import { useSearchParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { useContext, useEffect, useRef, useState } from "react";
import ContentLoader from "../../components/shared/ContentLoader";
import { Box, Stack, Typography } from "@mui/material";
import { Booking } from "../../hooks/useCart";
import { toast } from "react-toastify";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import useTitle from "../../hooks/useTitle";
import CartBookingCard from "../../components/cart/BookingCard";

interface props {
  title?: string;
}
const CheckIn: React.FC<props> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId")!;
  const authToken = searchParams.get("authToken")!;

  const { checkIn } = useUser();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [checkedInUser, setCheckedInUser] = useState<any>();
  const hasCalledAPI = useRef(false);

  const {
    company
  } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    if (!userId || hasCalledAPI.current || !authToken) return;
    toast("Check-in Successful", {
      type: "success",
    });
    hasCalledAPI.current = true;
    const handleCheckIn = async () => {
      try {
        const response = await checkIn(userId, company!, authToken);
        setBookings(response?.bookings);
        setCheckedInUser(response?.user);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (userId && authToken && company?.id) handleCheckIn();
  }, [userId , authToken , company?.id]);

  return (
    <ContentLoader isLoading={isLoading}>
      <Stack sx={{ width: "100%", gap: "32px", mt: "35px" }}>
        <Box alignItems={"center"} textAlign={"center"}>
          <Typography>{`${checkedInUser?.firstName} ${checkedInUser?.lastName}`}</Typography>
          <Typography>{`${checkedInUser?.email}`}</Typography>
        </Box>
        {bookings.map((booking) => (
          <CartBookingCard booking={booking} isCancelAddon={false} key={booking.id} />
        ))}
      </Stack>
    </ContentLoader>
  );
};

export default CheckIn;
