import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from "../../Utils/constants";
import Typography from "@mui/material/Typography"; // Add this import

import DaySelector from "../../components/Calendar/DaySelector";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";

import useTitle from "../../hooks/useTitle";

import ClassSessionSelector from "../../components/Calendar/ClassSessionSelector";
import ClassSelector from "../../components/Calendar/ClassSelector";
import useClassSessions from "../../hooks/useClassSessions";
import dayjs from "dayjs";
import { ClassCalendarWrapper } from "./style";
import CustomFooter from "../../components/shared/footer/CustomFooter";
import { useDaySelection } from "../../hooks/useDaySelection";
import DFCFooter from "../../components/shared/footer/DFCFooter";

interface IProps {
  title: string;
}

const ClassCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");
  const branchId = searchParams.get("branchId");
  const resourceId = searchParams.get("resourceId");
  const marketplaceName = searchParams.get("marketplaceName");

  const { selectedService, company, selectedDay, setSelectedDay , selectedResource, setMarketplaceName , marketplaceCompany } =
    useContext(CheckoutContext) as CheckoutContextProps;

  const { isLoading: isLoadingSessions, sessions  } = useClassSessions(
    company?.id || "",
    selectedService,
    selectedResource,
    dayjs(selectedDay, "DD/MM/YYYY").format("YYYYMMDD")
  );

  const { selectedPickerDate, setSelectedPickerDate } = useDaySelection();

  useEffect(() => {
    if (marketplaceName) setMarketplaceName(marketplaceName);
  }, [marketplaceName])
  

  return (
    <ClassCalendarWrapper>
      <ClassSelector type="class" subType="fitness" serviceId={serviceId || ""} branchId={branchId || ""} resourceId={resourceId || ""} />
      <DaySelector
        selectedPickerDate={selectedPickerDate}
        setSelectedPickerDate={setSelectedPickerDate}
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
        onlySevenDaysInAdvance={company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID}
      />

      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && (
        <Typography
        variant="body2"
         sx={{
          marginBottom: 2,
          fontStyle: 'italic',
          color: 'darkred',
          textAlign: "center"
        }}
        >
          Class timetable is updated daily and subject to change. <br/>
          Classes are not bookable they are filled on a First come – first served basis.
        </Typography>
      )}

      <ClassSessionSelector
        sessions={sessions}
        isLoading={isLoadingSessions}
        type="fitness"
      />
      
      

      {company?.hasFooter && <CustomFooter company={marketplaceCompany || company}/>}

      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && <DFCFooter />}

    </ClassCalendarWrapper>
  );
};

export default ClassCalendar;
