import { Button, OutlinedInput, Typography } from "@mui/material";
import { PromocodeInputWrapper } from "./style";
import usePackage, { ValidationOutput } from "../../../hooks/usePackage";
import { LoadingButton } from "@mui/lab";
import { useContext, useState } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

interface IProps {
  promocode: string;
  setPromocode: (val: string) => void;
  validationOutput: ValidationOutput | undefined;
  setPackageValidationOutput: React.Dispatch<
    React.SetStateAction<ValidationOutput | undefined>
  >;
  type: "Promocode" | "Package"
}
const PromocodeRedemption: React.FC<IProps> = ({
  promocode,
  setPromocode,
  validationOutput,
  setPackageValidationOutput,
  type
}) => {
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage , setErrorMessage] = useState<string>("");
  const { checkPackageValidity } = usePackage();
  const { company, bookingCart } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const onClickApply = async () => {
    try {
      setIsValidating(true);
      setIsError(false);
      const voucherValidation = await checkPackageValidity({
        companyId: company ? company.id : "",
        cartId: bookingCart.length > 0 ? bookingCart[0].cartId : "",
        voucherCode: promocode,
      });
      setPackageValidationOutput(voucherValidation);
    } catch (error: any) {
      setIsError(true);
      setErrorMessage(error.response.data.message)
      setPackageValidationOutput(undefined);
    }
    setIsValidating(false);
  };

  const onClickCancel = () => {
    setPromocode("");
    setPackageValidationOutput(undefined);
  };
  return (
    <PromocodeInputWrapper>
      <OutlinedInput
        placeholder="Enter Promo Code"
        value={promocode}
        onChange={(e) => setPromocode(e.target.value)}
        className="package-code-input"
        endAdornment={
          validationOutput?.valid ? (
            <Button
              onClick={onClickCancel}
              color={"error"}
              className="package-code-button"
            >
              Cancel
            </Button>
          ) : (
            <LoadingButton
              onClick={onClickApply}
              color={"primary"}
              loading={isValidating}
              className="package-code-button"
              disabled={!(promocode.length>0)}
            >
              Apply
            </LoadingButton>
          )
        }
      />

      {isError && (
        <Typography color={"error"}>{errorMessage}</Typography>
      )}
    </PromocodeInputWrapper>
  );
};

export default PromocodeRedemption;
