import { MouseEventHandler } from "react";
import PrimaryBtn from "../../FormCollection/PrimaryBtn";

interface IProps {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
}
const PurchasePackageButton: React.FC<IProps> = ({ handleClick, label }) => {
  return <PrimaryBtn text={label} onClick={handleClick} />;
};

export default PurchasePackageButton;
