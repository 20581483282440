import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { down } from "styled-breakpoints";
export const CustomizedTextFieldWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  margin: 15px 0px;

  ${down("md")} {
    width: 100%;
  }

  .textfield-label {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding-left: 10px;

    span {
      color: #5182ff;
    }
  }

  .textfield-input {
    border: 1px solid #d1d5db;
    box-sizing: border-box;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 10px;
    width: 95%;

    ${down("md")} {
      width: 100%;
    }
  }
`;
