import React, { useContext, useEffect } from "react";
import { Package, ValidationOutput } from "../../../hooks/usePackage";
import usePackage from "../../../hooks/usePackage";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { PackageSelectorWrapper } from "../style";
import { getCurrency } from "../../../Utils/format";

interface IProps {
  packages: Package[];
  selectedPackage: string;
  setSelectedPackage: React.Dispatch<React.SetStateAction<string>>;
  setIsValidating: React.Dispatch<React.SetStateAction<boolean>>;
  setPackageValidationOutput: React.Dispatch<
    React.SetStateAction<ValidationOutput | undefined>
  >;
}
const PackageSelector: React.FC<IProps> = ({
  packages,
  selectedPackage,
  setSelectedPackage,
  setIsValidating,
  setPackageValidationOutput,
}) => {
  const { checkPackageValidity } = usePackage();
  const { company, bookingCart } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const handleChange = async (event: SelectChangeEvent) => {
    setSelectedPackage(event.target.value);
  };

  useEffect(() => {
    const validateVoucher = async () => {
      try {
        setIsValidating(true);
        const voucherValidation = await checkPackageValidity({
          companyId: company ? company.id : "",
          cartId: bookingCart.length > 0 ? bookingCart[0].cartId : "",
          voucherCode: selectedPackage,
        });
        setPackageValidationOutput(voucherValidation);
      } catch (error) {
        setPackageValidationOutput(undefined);
      }
      setIsValidating(false);
    };

    if (
      selectedPackage &&
      selectedPackage !== "promo-code" &&
      selectedPackage !== "membership"
    ) {
      validateVoucher();
    } else if (selectedPackage && selectedPackage === "promo-code") {
      setPackageValidationOutput(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackage, bookingCart]);

  return (
    <PackageSelectorWrapper>
      <Select
        onChange={handleChange}
        value={selectedPackage}
        displayEmpty={true}
        renderValue={(value) =>
          value !== "" ? value : "Packages or Promo Code"
        }
        className="select"
      >
        {packages.map((currentPackage) => (
          <MenuItem key={currentPackage.id} value={currentPackage.code}>{`${currentPackage.code
            } - ${currentPackage.totalCredit - currentPackage.usedCredit
            } ${getCurrency(company?.currency)}`}</MenuItem>
        ))}

        <MenuItem value="promo-code">Promo Code</MenuItem>
      </Select>
    </PackageSelectorWrapper>
  );
};

export default PackageSelector;
