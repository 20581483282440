import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FormInput from "../../FormCollection/FormInput";
import PrimaryBtn from "../../FormCollection/PrimaryBtn";
import { IFormUIProps } from "./Types";
import { RegistrationStep } from "../../../Utils/constants";
import { useAuth } from "../../../context/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SocialMediaLogin from "../SocialMediaLogin";

const FormUI: React.FC<IFormUIProps> = ({
  form,
  formType,
  onSubmit,
  setFormType,
  isLoading,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;
  const isMd = useMediaQuery("(min-width:600px)");

  const { resetPassword } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleResetPassword = useCallback((data: any) => {
    resetPassword(data.email);
    setIsResetButtonDisabled(true);
    
    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    
    // Set new timer
    timerRef.current = setTimeout(() => {
      setIsResetButtonDisabled(false);
      timerRef.current = null;
      console.log("[Reset button disabled]");
    }, 60000); // 60000 ms = 1 minute
  }, [resetPassword]);

  useEffect(() => {
    return () => {
      // Clear the timeout when component unmounts
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <>
      {/* Email input */}
      <Box
        component={"form"}
        sx={{
          width: "100%",
          padding: "47px",
          pb: "16px",
          maxWidth: "570px",
          margin: "0 auto",
        }}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {formType === RegistrationStep.Login && (
          <Typography
            sx={{
              fontSize: "12px",
              color: "1a1a1a",
              textAlign: "center",
              marginBottom: "16px",
              ...(isMd && {
                fontSize: "16px",
              }),
            }}
          >
            Login with your details and book your court today.
          </Typography>
        )}
        <FormInput
          error={errors.email}
          placeholder={"Email Address*"}
          register={register("email")}
          id="email"
          type="email"
        />

        {formType === RegistrationStep.Login && (
          <>
            <FormInput
              error={errors.password}
              placeholder={"Password*"}
              register={register("password")}
              id="password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />  } 
                </IconButton>
              }
            />

            <Button
              variant="text"
              onClick={handleSubmit(handleResetPassword)}
              disabled={isResetButtonDisabled}
              sx={{
                padding: 0,
                textTransform: "none",
                color: isResetButtonDisabled ? "#999" : "#666",
                fontSize: "12px",
                marginBottom: "16px",
                ...(isMd && {
                  fontSize: "14px",
                }),
              }}
            >
              {isResetButtonDisabled ? "Reset email sent" : "Forgot Password?"}
            </Button>
          </>
        )}

        <Button
          sx={{
            width: "100%",
            height: "35px",
            textTransform: "uppercase",
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "white",
            fontSize: "12px",
            fontWeight: "bold",
            ...(isMd && {
              fontSize: "16px",
              height: "48px",
            }),
            "&:hover": {
              backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
              opacity: 0.9,
            },
          }}
          type="submit"
        >
          {formType === RegistrationStep.Login ? "Login" : "Continue"}
        </Button>
      </Box>
    </>
  );
};

export default FormUI;
