import React, { useContext, useEffect, useState, useMemo } from "react";
import { ProfileWrapper } from "./style";
import useProfile from "../../hooks/useProfile";
import { CartData, VoucherData } from "../../hooks/useProfile";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import useTitle from "../../hooks/useTitle";
import ProfileTabsSwitcher from "../../components/profile/ProfileTabs/ProfileTabsSwitcher";
import ProfileTab from "../../components/profile/ProfileTabs/ProfileTab";
import { parseDateTimeString } from "../../Utils/format";
import UpcomingBookingsTab from "../../components/profile/ProfileTabs/UpcomingBookingsTab";
import PreviousBookingsTab from "../../components/profile/ProfileTabs/PreviousBookingsTab";
import ContentLoader from "../../components/shared/ContentLoader";

interface BookingDetailsProps {
  title?: string;
}

const Profile: React.FC<BookingDetailsProps> = ({ title }) => {
  useTitle(title);
  const [activeTab, setActiveTab] = useState<
    "profile" | "upcoming" | "previous"
  >("profile");
   
  const { getUserBookings, getUserVouchers, getUserToken } = useProfile();
  const [profileData, setProfileData] = useState<{
    carts: CartData[];
    vouchers: VoucherData[];
    authToken: string;
  }>({ carts: [], vouchers: [], authToken: "" });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const { user } = useAuth();

  useEffect(() => {
    const getProfileData = async () => {
      setIsLoading(true);
      try {
        const [bookingData, vouchersData, authToken] = await Promise.all([
          getUserBookings(),
          getUserVouchers(),
          getUserToken()
        ]);
        setProfileData({ carts: bookingData, vouchers: vouchersData, authToken });
      } catch (error) {
        setProfileData({ carts: [], vouchers: [], authToken: "" });
      } finally {
        setIsLoading(false);
      }
    };

    if (company) {
      getProfileData();
    }
  }, [company]);

  const filterAndEnhanceBookings = (carts: CartData[], type: "prev" | "upcoming") => {
    const newArr = carts
      .map((cart: CartData) => {
        return cart.bookings
          .filter((booking) =>
            type === "upcoming"
              ? parseDateTimeString(booking.date, booking.startTime) >= dayjs()
              : parseDateTimeString(booking.date, booking.startTime) < dayjs()
          )
          .map((booking) => {
            return {
              ...booking,
              addonPurchases: cart.addons.filter(
                (addon) => addon.linkedBookingId === booking.id
              ),
            };
          });
      })
      .flat(2);
    return newArr;
  };

  const { upcomingBookings, previousBookings } = useMemo(() => ({
    upcomingBookings: filterAndEnhanceBookings(profileData.carts, "upcoming"),
    previousBookings: filterAndEnhanceBookings(profileData.carts, "prev")
  }), [profileData.carts]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case "profile":
        return (
          <ProfileTab
            user={user}
            authToken={profileData.authToken}
            vouchers={profileData.vouchers}
          />
        );
      case "upcoming":
        return <UpcomingBookingsTab upcomingBookings={upcomingBookings} />;
      case "previous":
        return <PreviousBookingsTab previousBookings={previousBookings} />;
      default:
        return null;
    }
  };

  return (
    <ProfileWrapper>
      <ContentLoader isLoading={isLoading}>
        <>
          <ProfileTabsSwitcher
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {renderActiveTab()}
        </>
      </ContentLoader>
    </ProfileWrapper>
  );
};

export default Profile;
