import React from 'react';
import { Typography } from '@mui/material';
import PaginatedBookings from '../PaginatedBookings';
import { Booking } from '../../../hooks/useCart';

interface PreviousBookingsTabProps {
  previousBookings: Booking []; 
}

const PreviousBookingsTab: React.FC<PreviousBookingsTabProps> = ({ previousBookings }) => {
  return (
    <>
      {previousBookings?.length ? (
        <PaginatedBookings
          title="Previous Bookings"
          bookings={previousBookings}
          isCancelAddon={false}
          showCancelOption={false}
        />
      ) : (
        <Typography variant="body1" color="text.secondary">
          No previous bookings.
        </Typography>
      )}
    </>
  );
};

export default PreviousBookingsTab;
