import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { ValidationResponse } from "../../hooks/useVoucherValidator";
import { SESSION_DURATION } from "../../Utils/constants";
import PackageDetailsItem from "./PackageDetailsItem";
import { PackageDetailsWrapper } from "./style";
import { getCurrency } from "../../Utils/format";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";

interface IProps {
  validationResponse?: ValidationResponse;
  isValidVoucher: boolean;
}

const PackageDetails: React.FC<IProps> = ({
  validationResponse,
  isValidVoucher,
}) => {
  const currentCredit =
    validationResponse!.remainingMinutesBeforeBooking / SESSION_DURATION;

  const remainingCredit =
    validationResponse!.remainingMinutesAfterBooking / SESSION_DURATION;

  const redeemedCredit = currentCredit - remainingCredit;

  const { company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  return (
    <PackageDetailsWrapper>
      <Typography className="package-details-label">Package Details</Typography>
      <Box className="package-details-items">
        <PackageDetailsItem
          label="Current Package Credits"
          value={`${currentCredit} Sessions`}
          valueColor="#5182FF"
        />
        {isValidVoucher && (
          <PackageDetailsItem
            label="Credits to be Redeemed"
            value={`${redeemedCredit} Sessions`}
            valueColor="#EB3D3D"
          />
        )}
        {isValidVoucher && (
          <PackageDetailsItem
            label="New Balance"
            value={`${remainingCredit} Sessions`}
            valueColor="#5182FF"
          />
        )}
        {isValidVoucher && (
          <PackageDetailsItem
            label="Total to be Paid"
            value={`0 ${getCurrency(company?.currency)}`}
            valueColor="#808080"
          />
        )}
      </Box>
    </PackageDetailsWrapper>
  );
};

export default PackageDetails;
