import { Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import { ProfileTabsSwitcherWrapper } from "./style";

export interface IProps {
  activeTab: "profile" | "upcoming" | "previous";
  setActiveTab: React.Dispatch<React.SetStateAction<"profile" | "upcoming" | "previous">>;
}

const ProfileTabsSwitcher: React.FC<IProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const theme = useTheme();

  const onChangeTabHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: "profile" | "upcoming" | "previous"
  ) => {
    setActiveTab(value);
  };

  return (
    <ProfileTabsSwitcherWrapper theme={theme}>
      <Tabs
        value={activeTab}
        onChange={onChangeTabHandler}
      >
        <Tab
          label="Profile"
          value="profile"
        />
        <Tab
          label="Upcoming bookings"
          value="upcoming"
        />
        <Tab
          label="Previous bookings"
          value="previous"
        />
      </Tabs>
    </ProfileTabsSwitcherWrapper>
  );
};

export default ProfileTabsSwitcher;
