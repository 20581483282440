import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const MembershipDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;

  align-items: flex-start;

  .package-details-label {
    color: #080133;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .package-details-items {
    width: 100%;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 40%);
    border-radius: 16px;
    background-color: #fff;
    padding: 10px 20px;
    margin-top: 10px;
  }
`;
