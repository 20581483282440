import {
  Box,
  Input,
  InputLabel,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { IInputFieldProps } from "./Types";

const FormInput: React.FC<IInputFieldProps> = ({
  placeholder,
  id,
  type = "text",
  register,
  error,
  label,
  endAdornment,
  autoComplete = "off",
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery("(min-width:600px)");
  return (
    <Box sx={{ marginBottom: "16px" }}>
      {label && (
        <InputLabel
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            marginBottom: "16px",
            alignSelf: "flex-start",
            color: "#171717",
          }}
        >
          {label}
        </InputLabel>
      )}
      <Input
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        id={id}
        sx={{
          border: "1px solid #ccc",
          p: "3px",
          pl: "16px",
          width: "100%",
          "&::before": {
            content: "none",
          },
          "&::after": {
            content: "none",
          },
          "&::placeholder": {
            color: "green",
          },
          ...(isMd && {
            fontSize: "16px",
          }),
        }}
        inputProps={{
          sx: {
            fontSize: "12px",
            color: "#333",
            "&::placeholder": {
              opacity: "1",
              "&::after": {
                content: '"*"',
                color: "red",
                display: "inline-block",
              },
            },
            ...(isMd && {
              fontSize: "16px",
            }),
          },
        }}
        error={!!error}
        {...register}
        endAdornment={endAdornment}
      />
      {error && (
        <Typography
          sx={{
            fontSize: "15px",
            color: "red",
            marginTop: "8px",
          }}
        >
          {error.message as string}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(FormInput);
