import React from 'react';
import { Controller, Control, FieldValues, FieldPath, FieldError } from 'react-hook-form';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete';

interface FormAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  name: any;
  control: any;
  label: string;
  required?: boolean;
  error?: FieldError;
  options: T[];
  placeholder?: string;
}

export function FormAutocomplete<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  name,
  control,
  label,
  required = false,
  error,
  placeholder,
  options,
  ...autocompleteProps
}: FormAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error: fieldError } }) => (
        <Box>
          <Autocomplete
            {...autocompleteProps}
            options={options}
            value={value}
            onChange={(event, newValue) => {
              if (newValue && typeof newValue === 'object' && 'value' in newValue) {
                onChange((newValue as any).label);
              } else {
                onChange(newValue);
              }
            }}
            renderInput={(params) => (
              <>
                <Typography 
                  sx={{ 
                    mb: 1,
                    fontFamily: 'Dubai-Regular',
                    fontSize: '14px',
                  }}
                >
                  {label}{required && ' *'}
                </Typography>
                <TextField
                  {...params}
                  placeholder={placeholder}
                  required={required}
                  error={!!fieldError}
                  inputRef={ref}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '70px',
                      borderRadius: '16px',
                      backgroundColor: '#fff',
                      boxShadow: '0 6px 40px rgba(208, 208, 208, 0.4)',
                      fontSize: '16px',
                      '& fieldset': {
                        border: 'none',
                      },
                    },
                  }}
                />
              </>
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ fontFamily: 'Dubai-Regular' }}>
                {(option as any).label}
              </li>
            )}
          />
          {(fieldError || error) && (
            <Typography color="error" variant="caption" sx={{ mt: 0.5, ml: 1 }}>
              {fieldError?.message || error?.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}
