import React, { useContext, useMemo, useCallback, useState, useEffect } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { Box } from "@mui/system";
import Heading from "../../shared/Heading";
import SportsTabs from "../ServiceSelector/Tabs";
import { ServiceSelectorWrapper } from "../ServiceSelector/style";
import { Service } from "../../../hooks/useCompany";
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from "../../../Utils/constants";

interface IProps {
  type: "class" | "coach" | "standard";
  subType?: "fun" | "gym" | "kids" | "fitness" | "pods";
  serviceId?: string;
  branchId?: string;
  resourceId?: string;
  uponSelectCallback?: (value: string) => void;
}

const shouldConsiderThisService = (service: Service, type: string, subType?: string): boolean => {
  if (!service.type) return false;
  else if(type && subType) return service.type === type && service.subType === subType ;
  else if(type) return service.type === type && !service.subType;
  return false;
};
const KITE_BEACH_ID = '7147e79c997d42d89dcacee092e9a1ea';
const KITE_BEACH_COURTS = [
  {
    id: "57e60a08b74542aab72b8652a18ea12e",
    name: "SSS Beach Volleyball",
    serviceId: "1816cbeb50624ecd9a880ff410422659",
  },
  {
    id: "2fbd1ed22f7442b1b9d01709edd3693",
    name: "DP World Cricket Arena",
    serviceId: "4c2e3658dedd4edd9041e770c4db428c",
  },
  {
    id: "83ed97e08d3b488a66a99f9729dbaab1",
    name: "Emarat Padel Courts",
    serviceId: "09737594b26842f4a352e6bcd6ceb75c",
  },

  {
    id: "32e7b2a664ab4f2a84b65225fbd217c2",
    name: "Decathlon Sports Court Basketball",
    serviceId: "1dac51f821ef4caa9b0131e433df3fd3",
  },
  {
    id: "32e7b2a664ab4f2a84b65225fbd217c4",
    name: "Decathlon Sports Court Football",
    serviceId: "45fd1d80399d49c5ad229765716455b1",
  },
];

const ClassSelector: React.FC<IProps> = ({ subType, type, serviceId, branchId, resourceId, uponSelectCallback }) => {
  const [isInitialSelection, setIsInitialSelection] = useState(true);
  
  const { 
    company, 
    servicesMap, 
    branchesMap, 
    resourcesMap,
    setSelectedService, 
    setSelectedLocation, 
    setSelectedResource,
    selectedService, 
    selectedLocation,
    selectedResource 
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const locationOptions = useMemo(() => {
    if (!branchesMap || branchesMap.size === 0) return [];
    return Array.from(branchesMap.values())
      .filter(branch => {
        return Array.from(servicesMap?.values() || [] ).some(service => 
          service.branchId === branch.id && shouldConsiderThisService(service, type, subType)
        );
      })
      .map((branch) => ({
        value: branch.id,
        label: branch.name,
      }));
  }, [branchesMap, servicesMap, type, subType]);

  const servicesOptions = useMemo(() => {
    if (!servicesMap || servicesMap.size === 0) return [];

    return Array.from(servicesMap.values())
      .filter(service => shouldConsiderThisService(service, type, subType))
      .filter(service => service.branchId === selectedLocation)
      .map(service => ({
        value: service.id,
        label: service.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [servicesMap, type, subType, selectedLocation]);

  const resourceOptions = useMemo(() => {
    const options: any[] = [];
    if (!resourcesMap || resourcesMap.size === 0 || company?.id !== DUBAI_FITNESS_CHALLENGE_COMPANY_ID) return options;

    resourcesMap.forEach((resource) => {
      const hasMatchingService = resource.services.some(service => 
        servicesMap?.has(service.id) && 
        shouldConsiderThisService(servicesMap.get(service.id)!, type, subType) &&
        servicesMap.get(service.id)?.branchId === selectedLocation
      );

      if (hasMatchingService) {
        options.push({
          value: resource.id,
          label: resource.name,
        });
      }
    });

    options.sort((a, b) => a.label.localeCompare(b.label));
    if (company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && selectedLocation === KITE_BEACH_ID) {
      KITE_BEACH_COURTS.forEach((court) => {
        options.push({
          value: court.id,
          label: court.name,
        });
      });
    }
    return options;
  }, [resourcesMap, selectedService, servicesMap, type, subType, selectedLocation]);

  useEffect(() => {
    if(locationOptions.length === 0) {
      setSelectedLocation("");
      return;
    }
    
    let newSelectedLocation = locationOptions[0]?.value;

    if (branchId && isInitialSelection && branchesMap?.has(branchId)) {
      newSelectedLocation = branchId;
    }

    if (serviceId && isInitialSelection && servicesMap?.has(serviceId)) {
      const service = servicesMap.get(serviceId)!;
      newSelectedLocation = service?.branchId ?? '';
    }

    if (newSelectedLocation) {
      setSelectedLocation(newSelectedLocation);
    }
  }, [branchId, serviceId, locationOptions, setSelectedLocation, servicesMap , branchesMap]);


  useEffect(() => {
    if (servicesOptions.length === 0) {
      setSelectedService("");
      return;
    }

    let newSelectedService = servicesOptions[0]?.value;

    if (serviceId && servicesMap?.has(serviceId) && isInitialSelection) {
      newSelectedService = servicesMap.get(serviceId)?.id ?? '';
    }

    if (newSelectedService) {
      setSelectedService(newSelectedService);
    }
  }, [serviceId, servicesMap, servicesOptions, setSelectedService]);

  useEffect(() => {
    if (company?.id !== DUBAI_FITNESS_CHALLENGE_COMPANY_ID || resourceOptions.length === 0) {
      setSelectedResource("");
      return;
    }

    let newSelectedResource = resourceOptions[0]?.value;

    if (resourceId && resourcesMap?.has(resourceId) && isInitialSelection) {
      newSelectedResource = resourceId;
    }

    setSelectedResource(newSelectedResource);
  }, [resourceId, resourcesMap, resourceOptions, setSelectedResource]);

  const onServiceChange = useCallback((
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setIsInitialSelection(false);
    setSelectedService(value);
    uponSelectCallback?.(value);
  }, [setSelectedService, uponSelectCallback, setIsInitialSelection]);

  const OnBranchChange = useCallback((
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setIsInitialSelection(false);
    setSelectedLocation(value);
  }, [setIsInitialSelection, setSelectedLocation]);

  const onResourceChange = useCallback((
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    if (company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && KITE_BEACH_COURTS.map(b => b.id).includes(value)) {
      const court = KITE_BEACH_COURTS.find(c => c.id === value);
      window.location.href = `/calendar?companyId=${company.id}&branchId=${KITE_BEACH_ID}&serviceId=${court?.serviceId}`;
      return;
    }
    setIsInitialSelection(false);
    setSelectedResource(value);
  }, [setIsInitialSelection, setSelectedResource]);

  return (
    <ServiceSelectorWrapper>
      {(branchId && company?.id !== DUBAI_FITNESS_CHALLENGE_COMPANY_ID) && <Heading heading="Location" />}
      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && <Heading heading="30x30 Fitness Villages" />}

      {branchId &&
        <Box style={{
          width: "100%",
          marginBottom: 16
        }}>
          <SportsTabs
            onChange={OnBranchChange}
            value={selectedLocation}
            data={locationOptions}
          />
        </Box>
      }

      {company?.id !== DUBAI_FITNESS_CHALLENGE_COMPANY_ID && 
        <>
          <Heading heading="Class" />
          <SportsTabs
            onChange={onServiceChange}
            value={selectedService}
            data={servicesOptions}
          />
        </>
      }
      
      {(company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && resourceOptions.length > 1) && (
        <>
          <Heading heading="Zone" />
          <Box style={{ width: "100%" }}>
            <SportsTabs
              onChange={onResourceChange}
              value={selectedResource}
              data={resourceOptions}
            />
          </Box>
        </>
      )}
    </ServiceSelectorWrapper>
  );
};

export default React.memo(ClassSelector);
