import React from "react";
import { CircularProgress, Stack } from "@mui/material";
import { LoaderWrapper } from "./style";

interface IProps {
  isLoading: boolean;
  children: JSX.Element;
}

const ContentLoader: React.FC<IProps> = ({ isLoading, children }) => {
  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
      <Stack
        sx={{
          display: isLoading ? "none" : "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {children}
      </Stack>
    </>
  );
};

export default ContentLoader;
