import Gym from "../../assets/gym.png";
import Fitness from "../../assets/fitness.png";
import Book from "../../assets/book.png";
import Packcage from "../../assets/package.png";
import Badminaton from "../../assets/sports/badminton.svg";
import Basketball from "../../assets/sports/basketball.svg";
import Cricket from "../../assets/sports/cricket.svg";
import Football from "../../assets/sports/football.svg";
import Padel from "../../assets/sports/padel.svg";
import PickleBall from "../../assets/sports/pickleball.svg";
import TableTennis from "../../assets/sports/table-tennis.svg";
import Tennis from "../../assets/sports/tennis.svg";
import VolleyBall from "../../assets/sports/volleyball.svg";
import Teqball from "../../assets/sports/teqball.svg";
import dubaiKidsWorld from "../../assets/images/dubai-kids-world.jpg";
import dubaiKidsWorldMd from "../../assets/images/dubai-kids-world-md.jpg";
import gym from "../../assets/images/gym.jpg";
import gymMd from "../../assets/images/gym-md.jpg";
import fitness from "../../assets/images/fitness.jpg";
import fitnessMd from "../../assets/images/fitness-md.jpg";
import dsw from "../../assets/images/dsw.jpg";
import dswMd from "../../assets/images/dsw-md.jpg";
import { ICategory, ICategoryItem } from "./Types";
import { DSW_COMPANY_ID } from "../../Utils/constants";

export const data: ICategory[] = [
  {
    id: 2,
    title: "Gym and Fitness Classes",
    cards: [
      {
        id: 6,
        img: Gym,
        text: "Book a Gym Session",
        url: `/Gym?companyId=${DSW_COMPANY_ID}`,
      },
      {
        id: 7,
        img: Fitness,
        text: "Book a Fitness Class",
        url: `/class?companyId=${DSW_COMPANY_ID}`,
      },
    ],
  },
  {
    id: 3,
    title: "Dubai Kids World",
    cards: [
      {
        id: 8,
        img: Book,
        text: "Book a kids Session",
        url: `/KidsArea?companyId=${DSW_COMPANY_ID}`,
      },
    ],
  },
  {
    id: 4,
    title: "Packages and Gym Memberships",
    cards: [
      {
        id: 9,
        img: Packcage,
        text: "Buy a DSW Package",
        url: `/packages?companyId=${DSW_COMPANY_ID}`,
      },
      {
        id: 10,
        img: Gym,
        text: "Buy a Gym Membership",
        url: `/memberships?companyId=${DSW_COMPANY_ID}`,
      },
    ],
  },
];

export const categories: ICategoryItem[] = [
  {
    id: 1,
    title: "Gym",
    description:
      "For 2024, the gym now has free access for everyone within Dubai Sports World.",
    cta: "Free Access",
    free: true,
    img: gym,
    imgMd: gymMd,
    url: "#",
  },
  {
    id: 2,
    title: "Attend an Event",
    description:
      "This year - try a whole range of fitness classes, from spin to yoga in the Dubai Sports World fitness studio.",
    cta: "Book Now",
    img: fitness,
    imgMd: fitnessMd,
    url: `/fun-activities?companyId=${DSW_COMPANY_ID}`,
  },
  {
    id: 3,
    title: "Dubai Kids World",
    description:
      "Get ready for some family fun with our all new Dubai Kids World featuring supervised soft play park, adventure zone, trampolines, giant inflatable slides and a dedicated arts and craft corner.",
    cta: "Book Now",
    img: dubaiKidsWorld,
    imgMd: dubaiKidsWorldMd,
    url: `/KidsArea?companyId=${DSW_COMPANY_ID}`,
  },
  {
    id: 4,
    title: "DSW Packages",
    description:
      "Experience more for less! Check out all of our available packages.",
    cta: "View Packages",
    img: dsw,
    imgMd: dswMd,
    url: `/packages?companyId=${DSW_COMPANY_ID}`,
  },
];

export const sports = [
  {
    id: 1,
    name: "Badminton",
    imgUrl: Badminaton,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=7ns0GT7MwZ9993JBTNwt`,
  },
  {
    id: 2,
    name: "Basketball",
    imgUrl: Basketball,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=nV62kItoFBvZm9BAjK1m`,
  },
  {
    id: 3,
    name: "Cricket",
    imgUrl: Cricket,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=KRTIDqW8onexNSOWMwFw`,
  },
  {
    id: 4,
    name: "Cricket Net Practice",
    imgUrl: Cricket,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=a236fe9588e3472b9288c27e728ef2fb`,
  },
  {
    id: 5,
    name: "Football (5-a-side)",
    imgUrl: Football,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=5kXVgbrcmA0c7AILRjBt`,
  },
  {
    id: 6,
    name: "Football (7-a-side)",
    imgUrl: Football,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=dCPEhYhpi34BFpViEoFN`,
  },
  {
    id: 7,
    name: "Padel",
    imgUrl: Padel,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=TwDCOQf0477sPror19pf`,
  },
  {
    id: 8,
    name: "Pickleball",
    imgUrl: PickleBall,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=M2MTfX43IXcSNzjDvPDb`,
  },
  {
    id: 9,
    name: "Table Tennis",
    imgUrl: TableTennis,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=S6ys8SrXJaYWlwkA5vYu`,
  },
  {
    id: 10,
    name: "Tennis",
    imgUrl: Tennis,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=az8aaEMDCwei3RqYO7W0`,
  },
  {
    id: 11,
    name: "Teqball",
    imgUrl: Teqball,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=az8aaEMDCwei3RqYO7W0`,
  },
  {
    id: 12,
    name: "Volleyball",
    imgUrl: VolleyBall,
    url: `/Calendar?companyId=${DSW_COMPANY_ID}&serviceId=UanGHGjibMSeG751Hlwu`,
  },
];
