import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import DateCard from "./DateCard";
import CardAddonRow from "../CardAddonRow";
import { AddonPurchase, Booking } from "../../../hooks/useCart";
import { CartBookingCardWrapper } from "./style";
import BookingCardRow from "./BookingCardRow";

export interface cartBookingCardProps {
  onCancelBookingHandler?: (
    booking: Booking,
  ) => void;
  showCancelOption?: boolean;
  showRemoveFromCartOption?: boolean;
  isCancelAddon?: boolean;
  booking: Booking;
}

const CartBookingCard: React.FC<cartBookingCardProps> = ({
  booking,
  onCancelBookingHandler,
  isCancelAddon = true,
  showCancelOption = false,
  showRemoveFromCartOption = false,
}) => {
  const month = booking.date.slice(4, 6);
  const day = booking.date.slice(6);

  const cancelProfileBooking = () => {
    if (onCancelBookingHandler)
      onCancelBookingHandler(booking);
  };

  console.log("[booking]", booking);

  return (
    <CartBookingCardWrapper showCancelOption={showCancelOption}>
      {showCancelOption && (
        <Button
          className="cancel-button"
          onClick={cancelProfileBooking}
        >
          Cancel Booking
        </Button>
      )}

      <Stack direction="row">

        <DateCard day={day} month={month} />

        <Box className="content-wrapper">

          <Stack className="title-wrapper">
            <Stack spacing={0.5}>
              <Typography
                variant="h6"
                className="title"
              >
                {`${booking.service.serviceName} ${booking.quantity && booking.quantity > 1 ? `x${booking.quantity}` : ""
                }`}
              </Typography>

              {booking.resource && (
                <Typography variant="body2" color="text.secondary">
                  {booking.resource.resourceName}
                </Typography>
              )}
            </Stack>
            
            <BookingCardRow
              showRemoveFromCartOption={showRemoveFromCartOption}
              booking={booking}
            />
          </Stack>
          {!!booking.addonPurchases?.length &&
            booking.addonPurchases.map((addon: AddonPurchase) => (
              <CardAddonRow
                key={addon.id}
                addon={addon}
                isCancelAddon={isCancelAddon}
              />
            ))}
        </Box>
      </Stack>
    </CartBookingCardWrapper>
  );
};

export default CartBookingCard;
