import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import useClassSessions from "../../hooks/useClassSessions";
import useTitle from "../../hooks/useTitle";
import ClassSelector from "../../components/Calendar/ClassSelector";
import DaySelector from "../../components/Calendar/DaySelector";
import ClassSessionSelector from "../../components/Calendar/ClassSessionSelector";
import { PodsCalendarWrapper } from "./style";
import CustomFooter from "../../components/shared/footer/CustomFooter";
import { useDaySelection } from "../../hooks/useDaySelection";
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from "../../Utils/constants";
import { Typography } from "@mui/material";
import DFCFooter from "../../components/shared/footer/DFCFooter";

interface IProps {
  title: string;
}

const PodsClasses: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");
  const branchId = searchParams.get("branchId");

  const { selectedService, company, selectedDay, setSelectedDay , marketplaceCompany } =
    useContext(CheckoutContext) as CheckoutContextProps;

  const { selectedPickerDate, setSelectedPickerDate } = useDaySelection();

  const { isLoading: isLoadingSessions, sessions } = useClassSessions(
    company?.id || "",
    selectedService,
    dayjs(selectedDay, "DD/MM/YYYY").format("YYYYMMDD")
  );


  return (
    <PodsCalendarWrapper>
      <ClassSelector type="class" subType="pods" serviceId={serviceId || ""} branchId={branchId || ""}/>
      <DaySelector
        selectedPickerDate={selectedPickerDate}
        setSelectedPickerDate={setSelectedPickerDate}
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
        onlySevenDaysInAdvance={company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID}
      />

      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && (
        <Typography
          variant="body2"
         sx={{
          marginBottom: 2,
          fontStyle: 'italic',
          color: 'darkred',
          textAlign: "center"
        }}
        >
          Class timetable is updated daily and subject to change. <br/>
          Classes are not bookable they are filled on a First come – first served basis.
        </Typography>
      )}

      <ClassSessionSelector
        sessions={sessions}
        isLoading={isLoadingSessions}
        type="pods"
      />

      


      {company?.hasFooter && <CustomFooter company={marketplaceCompany || company}/>}

      {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && <DFCFooter />}

    </PodsCalendarWrapper>
  );
};

export default PodsClasses;
