import { Box } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Branch, Resource, Service } from "../../../hooks/useCompany";
import CustomizedSelect from "../../FormCollection/Select";
import { ServiceSelectorWrapper } from "./style";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import Heading from "../../shared/Heading";
import SportsTabs from "./Tabs";
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from "../../../Utils/constants";

interface IProps {
  branchesMap: Map<string, Branch>;
  servicesMap: Map<string, Service>;
  resourcesMap: Map<string, Resource>;
  branchId: string | null;
  serviceId: string | null;
  resourceId: string | null;
}
const ServiceSelector: React.FC<IProps> = ({
  branchesMap,
  servicesMap,
  resourcesMap,
  branchId,
  serviceId,
  resourceId,
}) => {
  const [isDropdownChanged, setIsDropdownChanged] = useState<Boolean>(false);
  const {
    company,
    selectedLocation,
    selectedService,
    shouldShowServiceDropdown,
    isAllResourcesOptionEnabled,
    selectedResource,
    setSelectedLocation,
    setSelectedService,
    setSelectedResource,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const service = servicesMap?.get(selectedService);


  const locationOptions = useMemo(() => {
    const options: any[] = [];
    if (!branchesMap || branchesMap.size === 0) return options;

    branchesMap.forEach((branch) => {
      // Check if the branch has any services with the correct type and subtype
      const hasValidServices = Array.from(servicesMap.values()).some(
        (service) =>
          service.branchId === branch.id &&
          service.type === "standard" &&
          !service.subType
      );

      if (hasValidServices) {
        options.push({
          value: branch.id,
          label: branch.name,
        });
      }
    });

    let newSelectedLocation = options[0].value;

    if (branchId && !isDropdownChanged && branchesMap.has(branchId)) {
      newSelectedLocation = branchId;
    }

    if (serviceId && !isDropdownChanged && servicesMap.has(serviceId)) {
      const service = servicesMap.get(serviceId);
      newSelectedLocation = service?.branchId;
    }

    if (resourceId && !isDropdownChanged && resourcesMap.has(resourceId)) {
      const resource = resourcesMap.get(resourceId);
      const service = resource?.services[0];
      newSelectedLocation = service?.branchId;
    }

    setSelectedLocation(newSelectedLocation);

    return options;
  }, [branchId, serviceId, resourceId, branchesMap, servicesMap]);

  const servicesOptions = useMemo(() => {
    const options: any[] = [];
    if (!servicesMap || servicesMap.size === 0) return options;

    servicesMap.forEach((service) => {
      if (
        service.branchId === selectedLocation && service.type === "standard" && !service.subType
      ) {
        if (selectedService === "") setSelectedService(service.id);
        options.push({
          value: service.id,
          label: service.name,
        });
      }
    });

    options.sort((a, b) =>
      a.label > b.label ? 1 : a.label < b.label ? -1 : 0
    );

    let newSelectedService = options[0]?.value;

    console.log("[TEST] newSelectedService", newSelectedService);
    if (serviceId && !isDropdownChanged && servicesMap.has(serviceId)) {
      const service = servicesMap.get(serviceId);
      newSelectedService = service?.id;
    }

    if (resourceId && !isDropdownChanged && resourcesMap.has(resourceId)) {
      const resource = resourcesMap.get(resourceId);
      const service = resource?.services[0];
      newSelectedService = service?.id;
    }

    setSelectedService(newSelectedService);


    return options;
  }, [serviceId, resourceId, servicesMap, selectedLocation]);

  const resourceOptions = useMemo(() => {
    const options: any[] = [];
    if (!resourcesMap || resourcesMap.size === 0) return options;

    options.push({ label: "All Resources", value: "" })

    resourcesMap.forEach((resource) => {
      if (resource.services.map(service => service.id).includes(selectedService)) {
        options.push({
          value: resource.id,
          label: resource.name,
        });
      }
    });

    let newSelectedResource = options[0]?.value;

    if (resourceId && !isDropdownChanged && resourcesMap.has(resourceId)) {
      newSelectedResource = resourceId;
    }

    if (service?.showResourcesSelector || (resourceId && !isDropdownChanged && resourcesMap.has(resourceId))) setSelectedResource(newSelectedResource);

    return options;
  }, [
    resourceId,
    resourcesMap,
    selectedLocation,
    selectedService,
    shouldShowServiceDropdown,
  ]);

  const onServiceChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setSelectedService(value);
    setSelectedResource("");
    setIsDropdownChanged(true);
  };

  const OnBranchChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setSelectedLocation(value);
    setSelectedResource("");
    setIsDropdownChanged(true);
  };

  const OnResourceChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setSelectedResource(value);
    setIsDropdownChanged(true);
  };


  return (
    <ServiceSelectorWrapper>
      <Box className="dropdown-menus sports-selector" style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}>

  {(branchId && company?.id !== DUBAI_FITNESS_CHALLENGE_COMPANY_ID) && <Heading heading="Location" />}

        {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && <Heading heading="30x30 Fitness Villages" />}

        {branchId &&
          <Box style={{
            width: "100%",
            marginBottom: 16
          }}>
            <SportsTabs
              onChange={OnBranchChange}
              value={selectedLocation}
              data={locationOptions}
            />
          </Box>
        }

        <Heading heading="Sport" />
        <Box style={{
          width: "100%",
          marginBottom: 16
        }}
        >
          <SportsTabs
            onChange={onServiceChange}
            value={selectedService}
            data={servicesOptions}
          />
        </Box>

        {service?.showResourcesSelector && <Heading heading="Choose a Resource" />}

        {service?.showResourcesSelector &&
          <Box style={{ width: "100%" }}>
            <SportsTabs
              onChange={OnResourceChange}
              value={selectedResource}
              data={resourceOptions}
            />
          </Box>
        }

      </Box>
    </ServiceSelectorWrapper>
  );
};

export default ServiceSelector;
