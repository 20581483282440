import styled from "@emotion/styled";
import { Box } from "@mui/material";

interface IProps {
  theme: any;
}

export const UserVoucherCardWrapper = styled(Box)<IProps>`
  width: 100%;
  margin: 13px 0;
  padding: 0 12px;
  border-radius: 12px;
  box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);

  .title {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 20px;
    text-align: left;
    font-weight: 700;
  }

  .details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 40px;
    text-align: left;
  }

  .detail-label {
    font-size: 18px;
    margin-right: 24px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .divider {
    width: 50%;
    margin: 8px auto;
  }
`;
