import { Box, Stack, styled } from "@mui/material";

export const DiscountRedemptionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;

  .header {
    color: #080133;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
`;

export const PackageValidationDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  .cancel-button {
    margin-top: 10px;
  }
`;

interface IProps {
  valuecolor: string;
}

export const PackageDetailsItemWrapper = styled(Stack)<IProps>`
  margin-top: 15px;
  width: 100%;

  .package-details-item-label {
    color: #080133;

    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
  }

  .package-details-item-value {
    color: ${(props) => props.valuecolor};
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
  }
`;

export const PackageSelectorWrapper = styled(Box)`
  margin-top: 10px;

  .select {
    width: 250px;
  }
`;
