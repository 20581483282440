import React, { useContext } from "react";
import {
  PackageValidationOutput,
  ValidationOutput,
} from "../../../hooks/usePackage";

import { Typography } from "@mui/material";
import PackageValidationDetails from "./PackageValidationDetails";
import ContentLoader from "../../shared/ContentLoader";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import { getCurrency } from "../../../Utils/format";

interface IProps {
  packageValidationOutput: ValidationOutput | undefined;
  selectedPackage: string;
  isValidating: boolean;
  setSelectedPackage: React.Dispatch<React.SetStateAction<string>>;
  setPackageValidationOutput: React.Dispatch<
    React.SetStateAction<ValidationOutput | undefined>
  >;
}
const SelectedPackageDetails: React.FC<IProps> = ({
  packageValidationOutput,
  selectedPackage,
  isValidating,
  setSelectedPackage,
  setPackageValidationOutput,
}) => {
  // const currentPackageAmount = (
  //   packageValidationOutput as PackageValidationOutput
  // )?.remainingCreditBeforeBooking!;

  // const newBalance = (packageValidationOutput as PackageValidationOutput)
  //   ?.remainingCreditAfterBooking!;

  // const amountRedeemed = currentPackageAmount - newBalance;

  const { company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;


  const isValid = selectedPackage && packageValidationOutput?.valid;

  const getCurrentPackageAmount = () => {
    if (packageValidationOutput?.type === "duration") {
      return `${(
        packageValidationOutput as PackageValidationOutput
      )?.remainingDurationBeforeBooking! / (company?.serviceDurationMultiples || 15)} Session`;
    } else if (packageValidationOutput?.type === "credit") {
      return `${(
        packageValidationOutput as PackageValidationOutput
      )?.remainingCreditBeforeBooking!} ${getCurrency(company?.currency)}`;
    } else if (packageValidationOutput?.type === "membership") {
      return `${(packageValidationOutput as PackageValidationOutput).remainingSessionsBefore!} Session`
    }
  }

  const getNewBalance = () => {
    if (packageValidationOutput?.type === "duration") {
      return `${(
        packageValidationOutput as PackageValidationOutput
      )?.remainingDurationAfterBooking! / (company?.serviceDurationMultiples || 15)} Session`;
    } else if (packageValidationOutput?.type === "credit") {
      return `${(
        packageValidationOutput as PackageValidationOutput
      )?.remainingCreditAfterBooking!} ${getCurrency(company?.currency)}`;
    } else if (packageValidationOutput?.type === "membership") {
      return `${(packageValidationOutput as PackageValidationOutput).remainingSessionsAfter!} Session`
    }
  }

  const getAmountRedeemed = () => {

    if (packageValidationOutput?.type === "duration") {
      const currentPackageAmount = (
        packageValidationOutput as PackageValidationOutput
      )?.remainingDurationBeforeBooking!
      const newBalance = (
        packageValidationOutput as PackageValidationOutput
      )?.remainingDurationAfterBooking!

      return `${(currentPackageAmount - newBalance) / (company?.serviceDurationMultiples || 15)} Session `;
    } else if (packageValidationOutput?.type === "credit") {
      const currentPackageAmount = (
        packageValidationOutput as PackageValidationOutput
      )?.remainingCreditBeforeBooking!
      const newBalance = (
        packageValidationOutput as PackageValidationOutput
      )?.remainingCreditAfterBooking!

      return `${currentPackageAmount - newBalance} ${getCurrency(company?.currency)}`;
    } else if (packageValidationOutput?.type === "membership") {
      const currentPackageAmount = (
        packageValidationOutput as PackageValidationOutput
      )?.remainingSessionsBefore!
      const newBalance = (
        packageValidationOutput as PackageValidationOutput
      )?.remainingSessionsAfter!

      return `${currentPackageAmount - newBalance} Session`;
    }
  }

  return (
    <ContentLoader isLoading={isValidating}>
      <>
        {isValid ? (
          <PackageValidationDetails
            currentPackageAmount={getCurrentPackageAmount()!}
            amountRedeemed={getAmountRedeemed()!}
            newBalance={getNewBalance()!}
            setSelectedPackage={setSelectedPackage}
            setPackageValidationOutput={setPackageValidationOutput}
          />
        ) : (
          <Typography>
            {`Not enough credits, remaining credits: ${getCurrentPackageAmount()}`}
          </Typography>
        )}
      </>
    </ContentLoader>
  );
};

export default SelectedPackageDetails;
