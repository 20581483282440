import { Typography } from "@mui/material";
import React, { useContext } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { AvailableStartingTimesDuration } from "../../../hooks/useAvailableStartingTimes";

import { formatDurationTime } from "../../../Utils/format";
import { DurationChipWrapper } from "./style";

interface IProps {
  duration: AvailableStartingTimesDuration;
  index: number;
  selectedDuration?: AvailableStartingTimesDuration | undefined;
  setSelectedDuration: React.Dispatch<
    React.SetStateAction<AvailableStartingTimesDuration | undefined>
  >;
}
const DurationChip: React.FC<IProps> = ({
  duration,
  index,
  selectedDuration,
  setSelectedDuration,
}) => {
  const { company, selectedResource, quantity } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;
  const handleClick = () => {
    setSelectedDuration(duration);
  };

  return (
    <DurationChipWrapper
      onClick={handleClick}
      isSelected={selectedDuration?.id === duration.id}
      className={`duration-chip ${
        selectedDuration?.id === duration.id ? "duration-chip--active" : ""
      }`}
    >
      <Typography
        className="duration"
        sx={{
          fontFamily: "Inter",
          fontSize: "16px !important",
          fontWeight: 400,
          lineHeight: "22.4px",
          whiteSpace: "nowrap",
        }}
      >
        {formatDurationTime(duration.durationTime, company!, selectedResource)}
      </Typography>
    </DurationChipWrapper>
  );
};

export default DurationChip;
