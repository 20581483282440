import React, { useContext } from "react";
import { Box } from "@mui/system";
import QRCode from "react-qr-code";
import { DSW_COMPANY_ID } from "../../../Utils/constants";
import { UserData } from "../../../hooks/useUser";
import { InfoBox, InfoLabel, InfoValue, UserInformationWrapper } from "./style";
import LandingPageTitle from "../../shared/LandingPageTitle";
import { CheckoutContext } from "../../../context/CheckoutContext";
import { CheckoutContextProps } from "../../../context/CheckoutContext";

export interface IProps {
  user?: UserData;
  authToken: string;
}

const UserInformationSection: React.FC<IProps> = ({
  user,
  authToken
}) => {
  const { firstName, lastName, email, id } = user || {};
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const qrCodeValue = `${window.location.origin}/CheckIn?companyId=${company?.id}&userId=${id}&authToken=${authToken}`;

  return (
    <>
      <LandingPageTitle title={`Profile - ${user?.firstName}`} />

      <UserInformationWrapper>
        <InfoBox>
          <InfoLabel>First Name</InfoLabel>
          <InfoValue>{firstName}</InfoValue>
        </InfoBox>
        <InfoBox>
          <InfoLabel>Last Name</InfoLabel>
          <InfoValue>{lastName}</InfoValue>
        </InfoBox>
        <InfoBox>
          <InfoLabel>Email</InfoLabel>
          <InfoValue>{email}</InfoValue>
        </InfoBox>
        <Box>
          <QRCode value={qrCodeValue} />
        </Box>
      </UserInformationWrapper>
    </>
  );
};

export default UserInformationSection;
