import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { ICategoriesProps } from "./Types";
import { useNavigate } from "react-router-dom";

const Categories: React.FC<ICategoriesProps> = ({ data }) => {
  const navigate = useNavigate();
  const isMd = useMediaQuery("(min-width:600px)");

  return (
    <Stack
      component={"section"}
      sx={{
        width: "100%",
        zIndex: 2,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        ...(isMd && {
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
        }),
      }}
    >
      {data.map((card, i) => (
        <Stack
          sx={{
            height: "360px",
            backgroundColor: "#4d4d4d",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
            color: "white",
            padding: "24px",
            ...(card.img && {
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${
                isMd ? card.imgMd : card.img
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }),
            ...(isMd && {
              height: "850px",
            }),
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto Condensed",
              fontSize: "28px",
              fontWeight: "bold",
              textTransform: "uppercase",
              whiteSpace: "nowrap",
              ...(isMd && {
                fontSize: "48px",
              }),
            }}
          >
            {card.title}
          </Typography>
          <Typography
            sx={{
              width: "100%",
              maxWidth: "356px",
              textAlign: "center",
              fontSize: "14px",
              ...(isMd && {
                height: "145px",
                fontSize: "18px",
              }),
            }}
          >
            {card.description}
          </Typography>

          <Button
            sx={
              card.free
                ? {
                    width: "208px",
                    height: "54px",
                    textTransform: "uppercase",
                    backgroundColor: "#5f5f5f",
                    color: "white",
                    fontFamily: "Roboto Condensed",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "2px dashed white",
                    letterSpacing: "4px",
                    ...(isMd && {
                      fontSize: "24px",
                      height: "56px",
                    }),
                    "&:hover": {
                      backgroundColor: "#5f5f5f !important",
                      opacity: 0.9,
                    },
                  }
                : {
                    width: "141px",
                    height: "35px",
                    textTransform: "uppercase",
                    backgroundColor: "#E11E37",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                    ...(isMd && {
                      fontSize: "16px",
                      height: "44px",
                    }),
                    "&:hover": {
                      backgroundColor: "#E11E37 !important",
                      opacity: 0.9,
                    },
                  }
            }
            onClick={() => {
              if (card.url !== "#") navigate(card.url);
            }}
          >
            {card.cta}
          </Button>
        </Stack>
      ))}
    </Stack>
  );
};

export default Categories;
