import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { Box, Typography } from "@mui/material";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import { useContext, useEffect, useRef } from "react";
import useTitle from "../../hooks/useTitle";
import { useAuth } from "../../context/AuthContext";
import ContentLoader from "../../components/shared/ContentLoader";
import { isValidUser } from "../../Utils/validate";
import { toast } from "react-toastify";
import BackToHomeButton from "../../components/shared/BackToHomeButton";

interface props {
  title?: string;
}
const EmailVerificationConfirmation: React.FC<props> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const verificationCompleteRef = useRef(false);

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const { user, isAuthLoading } = useAuth();

  useEffect(() => {
    if (!isAuthLoading && isValidUser(user) && !verificationCompleteRef.current) {
      toast("You are now verified!", {
        theme: "colored",
        type: "success",
      });
      verificationCompleteRef.current = true;
      navigate(`/My-profile?companyId=${company?.id}`);
    }
  }, [isAuthLoading, user, company, navigate]);

  if (isAuthLoading || isValidUser(user)) {
    return <ContentLoader isLoading={true}><></></ContentLoader>;
  }

  return (
    <ContentLoader isLoading={isAuthLoading}>
      <Box width={"90%"} marginTop={"20px"}>
        <Typography
          style={{
            fontSize: "18px",
            fontStyle: "normal",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          Thank you! you are now verified
        </Typography>
        <BackToHomeButton />
      </Box>
    </ContentLoader>
  );
};

export default EmailVerificationConfirmation;
