import { Box, OutlinedInput, Typography } from "@mui/material";
import React from "react";
import { CustomizedTextFieldWrapper } from "./style";

interface IProps {
  id: string;
  label: string;
  isRequired?: boolean;
  placeholder?: string;
  value: string;
  setValue: (value: string) => void;
}

const CustomizedTextField: React.FC<IProps> = ({
  id,
  value,
  setValue,
  label,
  isRequired,
  placeholder,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  return (
    <CustomizedTextFieldWrapper>
      <Typography className="textfield-label">
        {label} {isRequired && <span>*</span>}
      </Typography>
      <OutlinedInput
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="textfield-input"
      ></OutlinedInput>
    </CustomizedTextFieldWrapper>
  );
};

export default CustomizedTextField;
