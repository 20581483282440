import React from 'react';
import { WalletSectionWrapper, ButtonsContainer, WalletButton } from './style';
import googleWalletButton from '../../../assets/wallet/add_to_google_wallet.svg';
import appleWalletButton from '../../../assets/wallet/add_to_apple_wallet.svg';
import useWallets from '../../../hooks/useWallets';
import { toast } from 'react-toastify';

const WalletSection: React.FC = () => {
  const {
    generateGooglePassForUser,
    isLoadingGooglePassUser,
    googlePassUserError,
    generateApplePassForUser,
    isLoadingApplePassUser,
    applePassUserError,
  } = useWallets();

  const handleAddToGoogleWallet = async () => {
    try {
      const data = await generateGooglePassForUser();
      window.location.href = data.url;
    } catch (error) {
      console.error('Failed to add to Google Wallet:', googlePassUserError);
      toast.error("Failed to add to Google Wallet");
    }
  };

  const handleAddToAppleWallet = async () => {
    try {
      const response = await generateApplePassForUser();
      const walletUrl = response.pass[0];

      const link = document.createElement('a');
      link.href = walletUrl;
      link.download = 'apple-wallet-pass.pkpass';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to add to Apple Wallet:', applePassUserError);
      toast.error("Failed to add to Apple Wallet");
    }
  };

  return (
    <WalletSectionWrapper>
      <h2>Add to Your Wallet</h2>
      <ButtonsContainer>
        <WalletButton
          onClick={handleAddToGoogleWallet}
          aria-label="Add to Google Wallet"
          disabled={isLoadingGooglePassUser}
        >
          <img src={googleWalletButton} alt="Add to Google Wallet" />
        </WalletButton>
        <WalletButton
          onClick={handleAddToAppleWallet}
          aria-label="Add to Apple Wallet"
          disabled={isLoadingApplePassUser}
        >
          <img src={appleWalletButton} alt="Add to Apple Wallet" />
        </WalletButton>
      </ButtonsContainer>
      {googlePassUserError && <p>Error: {googlePassUserError}</p>}
      {applePassUserError && <p>Error: {applePassUserError}</p>}
    </WalletSectionWrapper>
  );
};

export default WalletSection;
