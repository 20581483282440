import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import CartCheckoutDetails from "../../components/cart/CartCheckoutDetails";
import ConfirmationHeader from "../../components/confirmation/ConfirmationHeader";
import useTitle from "../../hooks/useTitle";
import { Typography } from "@mui/material";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { ConfirmationWrapper } from "./style";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import BackToHomeButton from "../../components/shared/BackToHomeButton";

interface IProps {
  title: string;
}
const Confirmation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const cartId = searchParams.get("cartId") || "";
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <ConfirmationWrapper>
      <ConfirmationHeader searchParams={searchParams} />
      {cartId && (
        <CartCheckoutDetails cartId={cartId} shouldRedirectToCancel={true} />
      )}
      <Typography sx={{ my: 3 }} className="email-label">
        {company?.confirmationDisclaimer}
      </Typography>
      <BackToHomeButton />
    </ConfirmationWrapper>
  );
};

export default Confirmation;
