import React, { useContext, useMemo } from "react";
import Select from "react-select";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { DEFAULT_MAX_QUANTITY } from "../../../Utils/constants";

import { QuantitySelectorWrapper } from "./style";
import { Typography } from "@mui/material";

const QuantitySelector: React.FC = () => {
  const { quantity, setQuantity, selectedService, servicesMap } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const service = servicesMap?.get(selectedService);

  const options = useMemo(() => {
    const options = [];
    const maxQuantity = service?.maxQuantity || DEFAULT_MAX_QUANTITY;
    for (let index = 1; index <= maxQuantity; index++) {
      options.push({ value: index, label: index });
    }
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service?.maxQuantity, selectedService]);

  const onSelect = (option: any) => {
    console.log("TEST",option)
    setQuantity(option.value);
    console.log("TEST",quantity);
  };

  return (
    <QuantitySelectorWrapper>
      <Typography className="label">Select a Quantity</Typography>
      <Select
        id="quantity-selector"
        value={{ value: quantity, label: quantity }}
        options={options}
        onChange={onSelect}
        className="select"
      />
    </QuantitySelectorWrapper>
  );
};

export default QuantitySelector;
