import React, { useContext } from "react";
import { PricingCardRowProps } from "./Type";
import { Typography, Box } from "@mui/material";
import { formatPrice } from "../../Utils/format";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";

const PricingCardRow: React.FC<PricingCardRowProps> = ({
  label,
  value,
  currency,
  isDiscount,
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "7px",
      }}
    >
      <Typography variant="h6" sx={{ color: "#080133", fontWeight: 500 }}>
        {label}
      </Typography>
      <Typography
        component="span"
        sx={{ color: isDiscount ? "#D53D47" : "#808080", fontWeight: 100 }}
      >
        {formatPrice(value, company)}
      </Typography>
    </Box>
  );
};

export default PricingCardRow;
