import React from "react";
import {
  AvailableStartingTimesDuration,
  AvailableStartingTimesResource,
} from "../../../hooks/useAvailableStartingTimes";
import DurationChip from "./DurationChip";
import { DurationListWrapper } from "./style";
import { useMediaQuery } from "@mui/material";

interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
  selectedDuration?: AvailableStartingTimesDuration | undefined;
  setSelectedDuration: React.Dispatch<
    React.SetStateAction<AvailableStartingTimesDuration | undefined>
  >;
}
const DurationsList: React.FC<IProps> = ({
  availableStartingTimes,
  selectedDuration,
  setSelectedDuration,
}) => {
  const isMd = useMediaQuery("(min-width:600px)");
  return (
    <DurationListWrapper
      className="duration-wrapper"
      sx={{
        ...(!isMd &&
          availableStartingTimes?.duration?.length > 2 && {
            display: "grid !important",
            gridTemplateColumns: "repeat(3, 1fr)",
          }),
      }}
    >
      {availableStartingTimes &&
        availableStartingTimes.duration?.map((duration, index) => (
          <DurationChip
            key={index}
            duration={duration}
            index={index}
            selectedDuration={selectedDuration}
            setSelectedDuration={setSelectedDuration}
          />
        ))}
    </DurationListWrapper>
  );
};

export default DurationsList;
