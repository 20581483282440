import React, { useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import DayChip from "./DayChip";
import { DaysListWrapper } from "./style";

interface IProps {
  selectedDay: string;
  setSelectedDay: (day: string) => void;
  selectedPickerDate: Date;
}

const DaysList: React.FC<IProps> = ({
  selectedDay,
  setSelectedDay,
  selectedPickerDate,
}) => {
  const daysList = useMemo(() => {
    const days: Dayjs[] = [];
    for (let i = 0; i < 7; i++) {
      days.push(dayjs(selectedPickerDate).add(i, "day"));
    }
    return days;
  }, [selectedPickerDate]);

  return (
    <DaysListWrapper className="days-list">
      {daysList.map((day) => (
        <DayChip
          key={day.toISOString()}
          day={day}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
        />
      ))}
    </DaysListWrapper>
  );
};

export default DaysList;
