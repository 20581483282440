import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { ServiceSelectorWrapper } from "./style";
import { Sport } from "../../../hooks/useSportsAndLocations";
import { ReactComponent as WakeSport } from "../../../assets/sports/wake.svg";
import { ReactComponent as JetskiSport } from "../../../assets/sports/Jetski.svg";
import { ReactComponent as WaterskiSport } from "../../../assets/sports/waterski.svg";
import { ReactComponent as EFoil } from "../../../assets/sports/efoil.svg";
import { ReactComponent as StandupPaddle } from "../../../assets/sports/sup.svg";
import { ReactComponent as KayakSport } from "../../../assets/sports/Kayak.svg";
import { useSearchParams } from "react-router-dom";

interface IProps {
  selectedSportTag: string;
  setSelectedSportTag: React.Dispatch<React.SetStateAction<string>>;
  selectedLocationTag: string;
  setSelectedLocationTag: React.Dispatch<React.SetStateAction<string>>;
  sports: Sport[];
}

const sportIcons: Record<string, any> = {
  "Wakesurfing & Wakeboarding": <WakeSport width="47px" height="45px" />,
  Jetski: <JetskiSport width="60px" height="45px" />,
  Waterski: <WaterskiSport width="47px" height="45px" />,
  Kayak: <KayakSport width="47px" height="45px" />,
  Efoil: <EFoil width="47px" height="45px" />,
  "Standup Paddle Board (SUP)": <StandupPaddle width="47px" height="45px" />,
};

const SportSelector: React.FC<IProps> = ({
  selectedSportTag,
  setSelectedSportTag,
  selectedLocationTag,
  setSelectedLocationTag,
  sports,
}) => {
  const isMd = useMediaQuery("(min-width:600px)");
  const [searchParams] = useSearchParams();
  const sportName = searchParams.get("sportName");
  const SportsOptions = useMemo(() => {
    const options: any[] = sports?.map((sport: Sport) => ({
      value: sport.sport,
      label: sport.sport,
    }));

    if (options) {
      const selectedSport = sports.find((sport) => sport.sport === sportName);
      if (selectedSport) setSelectedSportTag(selectedSport.sport);
      else setSelectedSportTag(options[0]?.value);
    }
    return options;
  }, [sports]);

  const LocationOptions = useMemo(() => {
    const options: any[] = [{ value: "", label: "All Locations in Dubai" }];
    const selectedSport = sports.find(
      (sport) => sport.sport === selectedSportTag
    );

    selectedSport?.locations?.forEach((location) => {
      options.push({ value: location, label: location });
    });
    if (options) setSelectedLocationTag(options[0]?.value);
    return options;
  }, [selectedSportTag]);

  const onSportChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    const selectedSport = sports.find((sport) => sport.sport === value);
    if (selectedSport) setSelectedSportTag(selectedSport?.sport);
  };

  const OnLocationChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setSelectedLocationTag(value);
  };

  return (
    <ServiceSelectorWrapper>
      <Box
        className="dropdown-menus sports-selector"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "16px",
          marginBottom: "24px",
        }}
      >
        <Box
          style={{
            width: "100%",
            marginBottom: "48px",
          }}
        >
          <Tabs
            value={selectedSportTag}
            onChange={onSportChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              width: isMd ? "max-content" : "100%",
              margin: "0 auto",
              borderRadius: "10px",
              "& .MuiTabs-indicator": {
                backgroundColor: "#ccc",
              },
              "& .MuiTabs-flexContainer": {
                justifyContent: "start",
              },
              boxShadow: "0px 4px 4px 0px rgba(208, 208, 208, 0.4)",
            }}
          >
            {SportsOptions?.map((sport) => (
              <Tab
                key={sport.value}
                label={sport.label}
                value={sport.value}
                icon={sportIcons[sport.value]}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px !important",
                  padding: "6px !important",
                  height: "111px",
                  // minWidth: "90px",
                  textTransform: "capitalize",
                  fontWeight: 400,
                  textDecoration: "none",
                  color: "#000 !important",
                  gap: "11px",
                }}
              />
            ))}
          </Tabs>
        </Box>

        <Box
          style={{
            width: "100%",
            marginBottom: 16,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // maxWidth: "100%",
            // overflowX: "auto",
            // height: "36px",
            // gap: "8px",
          }}
        >
          {/* {LocationOptions?.map((location) => (
            <Box
              key={location.value}
              onClick={(e) => OnLocationChange(e, location.value)}
              sx={{
                height: "100%",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "22.4px",
                background:
                  selectedLocationTag === location.value
                    ? (theme) => theme.palette.primary.main
                    : "#fff",
                borderRadius: "10px",
                color:
                  selectedLocationTag === location.value ? "#fff" : "#808080",
                textTransform: "capitalize",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 8px",
                cursor: "pointer",
                flexShrink: 0,
                // boxShadow: "0px 6px 40px 0px rgba(208, 208, 208, 0.4)",
              }}
            >
              {location.label}
            </Box>
          ))} */}

          <Tabs
            value={selectedLocationTag}
            onChange={OnLocationChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              display: "flex",
              width: isMd ? "max-content" : "100%",
              margin: "0 auto",
              justifyContent: "space-between",
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTabScrollButton-root.Mui-disabled": {
                display: "none",
              },
              "& .MuiTabs-flexContainer": {
                justifyContent: "start",
                gap: "8px",
              },
            }}
          >
            {LocationOptions?.map((location) => (
              <Tab
                key={location.value}
                label={location.label}
                value={location.value}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "22.4px",
                  background: "#fff",
                  borderRadius: "10px",
                  color: "#808080",
                  textTransform: "capitalize",
                  "&.Mui-selected": {
                    background: (theme) => theme.palette.primary.main,
                    color: "#fff !important",
                  },
                  // boxShadow: 3,
                  // boxShadow: "0px 6px 40px 0px rgba(208, 208, 208, 0.4)",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </ServiceSelectorWrapper>
  );
};

export default SportSelector;
