import styled from "@emotion/styled";

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  // width: calc(100% - 40px);
  // margin: 0 auto;
  min-height: 100vh;

  // @media (min-width: 768px) {
  //   width: 80%;
  //   max-width: 768px;
  // }
`;
