import styled from "@emotion/styled";
import { Box } from "@mui/material";

const UserInformationWrapper = styled(Box)`
  width: 100%;
  padding: 0 25px;
  font-weight: 500;
  margin-top: 20px;
`;

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
`;

const InfoLabel = styled.h4`
  color: #171717;
  font-size: 18px;
  margin-right: 24px;
  width: 100px;
  text-align: left;
`;

const InfoValue = styled.p`
  color: #808080;
`;

export {
  UserInformationWrapper,
  InfoBox,
  InfoLabel,
  InfoValue,
};
