import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";

export const PackageDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .package-details-label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #33334f;
  }

  .package-details-items {
    width: 100%;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 40%);
    border-radius: 16px;
    background-color: #fff;
    padding: 10px 20px;
    margin-top: 10px;
  }
`;

export const PackageValidationDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;

  .cancel-button {
    margin-top: 10px;
  }
`;

interface IProps {
  valueColor: string;
}

export const PackageDetailsItemWrapper = styled(Stack)<IProps>`
  margin-top: 15px;
  width: 90%;
  padding: 20px;

  .package-details-item-label {
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    font-size: 18px;
    color: #33334f;
  }

  .package-details-item-value {
    color: ${(props) => props.valueColor};
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    font-size: 18px;
  }
`;

export const PackageSelectorWrapper = styled(Box)`
  margin-top: 10px;

  .select {
    width: 250px;
  }
`;
