import React from "react";
import { Typography, Divider, useTheme } from "@mui/material";
import { UserVoucherCardWrapper } from "./style";

export interface UserVoucherCardInfo {
  label: string;
  value: string;
  id?: string;
}

export interface UserVoucherCardProps {
  title: string;
  details: UserVoucherCardInfo[];
}

const UserVoucherCard: React.FC<UserVoucherCardProps> = ({ title, details }) => {

  const theme = useTheme();
  
  return (
    <UserVoucherCardWrapper theme={theme}>
      <Typography variant="h3" component="h3" className="title">
        {title}
      </Typography>
      {details.map((item, index) => (
        <React.Fragment key={item.id || index}>
          {item.id === "price" && <Divider className="divider" />}
          <div className="details-container">
            <Typography
              variant="h4"
              component="h4"
              className="detail-label"
            >
              {item.label}
            </Typography>
            <Typography variant="body1" className="detail-value">
              {item.value}
            </Typography>
          </div>
        </React.Fragment>
      ))}
    </UserVoucherCardWrapper>
  );
};

export default UserVoucherCard;
