import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useAuth } from "../../../context/AuthContext";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import PrimaryBtn from "../../FormCollection/PrimaryBtn";
import { DeleteAccountButtonWrapper } from "./style";

const DeleteAccountButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const navigate = useNavigate();
    const { company } = useContext(CheckoutContext) as CheckoutContextProps;
    const { deleteAccount } = useAuth();

    const handleOpenDialog = () => setShowDialog(true);
    const handleCloseDialog = () => setShowDialog(false);

    const handleDeleteAccount = async () => {
        await deleteAccount();
        handleCloseDialog();
        navigate(`/?companyId=${company?.id}`);
    };

    return (
        <DeleteAccountButtonWrapper>
            <PrimaryBtn
                text="Delete Account"
                onClick={handleOpenDialog}
                className="delete-account-btn"
            />
            <Dialog
                open={showDialog}
                onClose={handleCloseDialog}
                className="delete-account-dialog"
            >
                <DialogTitle>
                    Are you sure you would like to delete your account?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action will permanently delete your account and all associated data, including any existing bookings.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleDeleteAccount}
                        autoFocus
                        className="delete-btn"
                    >
                        Delete Account
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCloseDialog}
                        className="cancel-btn"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </DeleteAccountButtonWrapper>
    );
};

export default DeleteAccountButton;
