import styled from "@emotion/styled";
import { down, up } from "styled-breakpoints";

export const ServiceSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  .dropdown-menus {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
    background: #ffffff;
    // border-radius: 16px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;

    ${up("md")} {
      align-items: center;
    }
    ${down("md")} {
      flex-direction: column;
      width: 100%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .sports-selector {
    padding: 0;
    box-shadow: none;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;
