import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { AvailableStartingTimesResource } from "../../../hooks/useAvailableStartingTimes";
import DurationsList from "./DurationsList";
import { DurationFilterWrapper } from "./style";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";

interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
}

const DurationFilter: React.FC<IProps> = ({ availableStartingTimes }) => {
  const {
    selectedDuration,
    setSelectedDuration
  } = useContext(CheckoutContext) as CheckoutContextProps;
  return (
    <DurationFilterWrapper>
      <Typography className="duration-label">Select Duration</Typography>
      <DurationsList availableStartingTimes={availableStartingTimes} selectedDuration={selectedDuration} setSelectedDuration={setSelectedDuration}/>
    </DurationFilterWrapper>
  );
};

export default DurationFilter;
