  import { useEffect, useState } from "react";
  import axiosInstance from "../services/axiosInstance";


  export interface Sport {
    sport: string;
    locations: string[];
  }

  const useSportsAndLocations = (marketplaceName?: string) => {

    const [sports , setSports] = useState<Sport[]>([]);
    const [isLoading , setIsLoading] = useState<boolean>(false);


    useEffect(()=> {
      const getSportsAndLocations = async () => {
          try {
            setIsLoading(true);
            const { data: response } = await axiosInstance.get(`/booking/marketplace-info/${marketplaceName}`);
            setSports(response);
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
          }
      };

      if(marketplaceName) getSportsAndLocations();

    },[])
    

    return {
      sports,
      isLoading
    }
  };

  export default useSportsAndLocations;
