export const sports = [
  { value: "", label: "Select your option" },
  {
    label: "Football",
    value: "Football",
  },
  {
    label: "Basketball",
    value: "Basketball",
  },
  {
    label: "Tennis",
    value: "Tennis",
  },
  {
    label: "Volleyball",
    value: "Volleyball",
  },
  {
    label: "Badminton",
    value: "Badminton",
  },
  {
    label: "Padel",
    value: "Padel",
  },
  {
    label: "Cricket",
    value: "Cricket",
  },
  {
    label: "Table Tennis",
    value: "Table Tennis",
  },
  {
    label: "Pickleball",
    value: "Pickleball",
  },
  {
    label: "Gym",
    value: "Gym",
  },
  {
    label: "Fitness Classes",
    value: "Fitness Classes",
  },
  {
    label: "Teqball",
    value: "Teqball"
  },
  {
    label: "Dubai Kids World",
    value: "Dubai Kids World"
  }
];

export const whereDidYouHearAboutUs = [
  { value: "", label: "Select your option" },
  {
    label: "Online Advertising",
    value: "Online Advertising",
  },
  {
    label: "Social Media",
    value: "Social Media",
  },
  {
    label: "Outdoor Ads",
    value: "Outdoor Ads",
  },
  {
    label: "Sports Academy",
    value: "Sports Academy",
  },
  {
    label: "Word of Mouth",
    value: "Word of Mouth",
  },
];


export const Genders = [
  { value: "", label: "Select your option" },
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    value: "other",
    label: "Prefer not to say"
  }
]
