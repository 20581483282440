import axios, { AxiosRequestConfig } from "axios";
import { auth } from "../config/firebase";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const idToken = await getIdToken();

  if (idToken) {
    config.headers!["idtoken"] = idToken;
    config.headers!["tenantId"] = auth.tenantId || "";
  }

  if (config.data) {
    config.data = filterEmptyAndUndefinedParams(config.data);
  }
  return config;
});

const getIdToken = async () => {
  let idToken: string | undefined;
  if (auth.currentUser) {
    idToken = await auth.currentUser.getIdToken();
  } else {
    await new Promise<void>((resolve) => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          idToken = await user.getIdToken();
        }
        resolve();
      });
    });
  }
  return idToken;
};

const filterEmptyAndUndefinedParams = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object?.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== "" && value !== undefined
    )
  );
};

export default axiosInstance;
