import React from "react";

const CvvField: React.FC = () => (
  <div>
    <label htmlFor="cvv">Security code</label>
    <div className="input-container cvv">
      <div className="icon-container">
        <img id="icon-cvv" src="images/card-icons/cvv.svg" alt="CVV" />
      </div>
      <div className="cvv-frame"></div>
      <div className="icon-container">
        <img
          id="icon-cvv-error"
          src="images/card-icons/error.svg"
          alt="Error"
        />
      </div>
    </div>
  </div>
);

export default CvvField;
