import React, { useContext } from "react";
import { UserData } from "../../../hooks/useUser";
import { VoucherData } from "../../../hooks/useProfile";
import DeleteAccountButton from "../../shared/DeleteAccountButton";
import UserMembershipsSection from "../UserVouchers/UserMembershipsSection";
import UserPackagesSection from "../UserVouchers/UserPackagesSection";
import UserInformationSection from "../UserInformationSection";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import WalletSection from "../WalletSection";

interface UserProfileContentProps {
  user?: UserData;
  authToken: string;
  vouchers: VoucherData[];
}

const ProfileTab: React.FC<UserProfileContentProps> = ({ user, authToken, vouchers }) => {

 const { company } = useContext(CheckoutContext) as CheckoutContextProps;
    
  return (
    <>
      <UserInformationSection user={user} authToken={authToken} />
      <WalletSection />
      {!company?.hideDiscountRedemptionSection && (
        <>
          <UserPackagesSection vouchers={vouchers} />
          <UserMembershipsSection vouchers={vouchers} />
        </>
      )}
      <DeleteAccountButton />
    </>
  );
};

export default ProfileTab;

