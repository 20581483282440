import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import TermsAgreementCard from "./TermsAgreementCard";
import { isValidEmail } from "../../Utils/validate";
import { UserInformationWrapper } from "../shared/userInformationForm/style";
import CustomizedTextField from "../FormCollection/TextField";
import PhoneNumberField from "../FormCollection/phoneNumberField";
import { formatPhoneNumber } from "../../Utils/format";
import { ValidationOutput } from "../../hooks/usePackage";

export interface IUserForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
}

interface IProps {
  handleClick: any;
  isLoading: boolean;
  packageValidationOutput?: ValidationOutput;
}

const GuestModeForm: React.FC<IProps> = ({ handleClick, isLoading , packageValidationOutput }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [country, setCountry] = useState("AE");

  const isDisabled = () => {
    return (
      firstName.length === 0 ||
      lastName.length === 0 ||
      email.length === 0 ||
      phone.length === 0 ||
      !isValidEmail(email)
    );
  };

  return (
    <UserInformationWrapper>
      <Typography variant="h6" className="user-information-label">
        {`User Information`}
      </Typography>
      <Box className="user-information">
        <Box className="user-information-row">
          <CustomizedTextField
            id="first_name"
            label="First Name"
            value={firstName}
            setValue={setFirstName}
            isRequired={true}
            placeholder="First Name"
          />

          <CustomizedTextField
            id="last_name"
            label="Last Name"
            value={lastName}
            setValue={setLastName}
            isRequired={true}
            placeholder="Last Name"
          />
        </Box>

        <Box className="user-information-row">
          <CustomizedTextField
            id="email"
            label="Email"
            value={email}
            setValue={setEmail}
            isRequired={true}
            placeholder="Email"
          />
          <PhoneNumberField
            phoneNumber={phone}
            setPhoneNumber={setPhone}
            country={country}
            setCountry={setCountry}
          />
        </Box>

        <TermsAgreementCard
          onClick={() =>
            handleClick({
              firstName,
              lastName,
              email,
              phone: formatPhoneNumber(phone, country),
            })
          }
          packageValidationOutput={packageValidationOutput}
          isLoading={false}
          disabled={isDisabled()}
        />
      </Box>
    </UserInformationWrapper>
  );
};

export default GuestModeForm;
