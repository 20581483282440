import React, { useState } from "react";
import { AvailableStartingTimesSlot } from "../../../hooks/useAvailableStartingTimes";
import SlotChip from "./SlotChip";
import { SlotsListWrapper } from "./style";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface IProps {
  slots: AvailableStartingTimesSlot[];
  addToCart: any;
  areAllOffPeakSlots: boolean;
  selectedSlot?: AvailableStartingTimesSlot;
  setSelectedSlot: (slot: AvailableStartingTimesSlot | undefined) => void;
  timezone?: string;
  initialVisibleCount?: number;
}

const SlotsList: React.FC<IProps> = ({
  slots,
  addToCart,
  areAllOffPeakSlots,
  selectedSlot,
  setSelectedSlot,
  timezone,
  initialVisibleCount,
}) => {
  const isMd = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const [lessMode, setLessMode] = useState<boolean>(true);

  const visibleSlots = slots.slice(
    0,
    lessMode && initialVisibleCount ? 9 : slots.length
  );

  const handleShowMore = () => {
    setLessMode(false);
  };

  const handleLessMore = () => {
    setLessMode(true);
  };

  return (
    <>
      <SlotsListWrapper
        theme={theme}
        sx={{
          ...(!isMd &&
            visibleSlots.length > 2 && {
              display: "grid !important",
              gridTemplateColumns: "repeat(3, 1fr)",
            }),
        }}
      >
        {visibleSlots.map((slot) => (
          <SlotChip
            key={slot.time}
            slot={slot}
            addToCart={addToCart}
            areAllOffPeakSlots={areAllOffPeakSlots}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            timezone={timezone}
          />
        ))}
      </SlotsListWrapper>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "20px",
          width: "100%",
          color: "#808080",
        }}
      >
        {lessMode && !!initialVisibleCount && (
          <Box>
            <Button
              style={{
                textTransform: "none",
              }}
              color="inherit"
              onClick={handleShowMore}
              startIcon={<KeyboardArrowDownIcon />}
            >
              More Times
            </Button>
          </Box>
        )}

        {!lessMode && !!initialVisibleCount && (
          <Box>
            <Button
              style={{
                textTransform: "none",
              }}
              color="inherit"
              onClick={handleLessMore}
              startIcon={<KeyboardArrowUpIcon />}
            >
              Less Times
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SlotsList;
