import React, { useContext } from "react";
import { Typography } from "@mui/material";

import PackageDetailsItem from "./PackageDetailsItem";
import { PackageDetailsWrapper } from "./style";
import { formatDuration, formatPrice } from "../../../Utils/format";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";

interface IProps {
  packageValue: number;
  packagePrice: number;
  packageCode?: string;
  packageName?: string;
  packageDuration?: number;
  type: string;
}

const PackageDetails: React.FC<IProps> = ({
  packageValue,
  packagePrice,
  packageCode,
  packageName,
  packageDuration,
  type
}) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;


  const formatTheValue = () => {

    if (type === "credit") {
      return formatPrice(packageValue, company);
    }

    if (type === "duration") {
      return formatDuration(packageValue, company?.serviceDurationMultiples!);
    }

    if (type === "membership") {
      return `${packageValue} Sessions`;
    }
  }


  console.log("package duration", packageDuration);
  return (
    <PackageDetailsWrapper>
      <Typography
        className="package-details-label"
        sx={{ textAlign: "left" }}
      >{packageName}</Typography>
      {packageCode && (
        <PackageDetailsItem label="Package Code" value={packageCode} />
      )}
      {!!packageValue && <PackageDetailsItem label="Package Value" value={formatTheValue()!} />}
      <PackageDetailsItem label="Package Price" value={`${formatPrice(packagePrice, company)}`} />
      {(!!packageDuration && packageDuration > 0) && <PackageDetailsItem label="Package duration" value={`${packageDuration} day${packageDuration > 1 ? "s" : ""}`} />}
    </PackageDetailsWrapper>
  );
};

export default PackageDetails;
