import styled from "@emotion/styled";
import { down } from "styled-breakpoints";

export const DeleteAccountButtonWrapper = styled.div`
    width: 90%;
  .delete-account-btn {
    margin: 24px 0;
  }

  .delete-account-dialog {
    .MuiDialog-paper {
      padding: 17px 13px 9px;
      border-radius: 12px;
      box-shadow: 0 10px 40px #D4D9E8;
    }

    .MuiDialogTitle-root {
      font-size: 12px;
      font-weight: 500;
      color: #808080;
      text-align: center;
      margin-bottom: 8px;
      padding: 0;
    }

    .MuiDialogContent-root {
      padding: 0;

      .MuiDialogContentText-root {
        font-size: 9px;
        font-weight: 500;
        color: #808080;
        text-align: center;
        margin-bottom: 12px;
      }
    }

    .MuiDialogActions-root {
      justify-content: center;

      .MuiButton-root {
        font-size: 10px;
        font-weight: 500;
        text-transform: none;
        border-radius: 6px;
        box-shadow: none;
      }

      .delete-btn {
        background-color: #FF0000;
        &:hover {
          background-color: #CC0000;
        }
      }

      .cancel-btn {
        background-color: #808080;
        &:hover {
          background-color: #666666;
        }
      }
    }
  }

  ${down("sm")} {
    // Add any mobile-specific styles here
  }
`;

