import React from "react";

const ExpiryDateField: React.FC = () => (
  <div>
    <label htmlFor="expiry-date">Expiry date</label>
    <div className="input-container expiry-date">
      <div className="icon-container">
        <img
          id="icon-expiry-date"
          src="images/card-icons/expiry-date.svg"
          alt="Expiry date"
        />
      </div>
      <div className="expiry-date-frame"></div>
      <div className="icon-container">
        <img
          id="icon-expiry-date-error"
          src="images/card-icons/error.svg"
          alt="Error"
        />
      </div>
    </div>
  </div>
);

export default ExpiryDateField;
