import { useState } from "react";
import { VoucherErrors } from "../Utils/constants";
import axiosInstance from "../services/axiosInstance";

interface ValidateVoucherInput {
  voucherCode: string;
  companyId?: string;
  serviceId?: string;
  serviceDurationId?: string;
}

interface VoucherValidatorInput {
  setIsPackageCodeValid: (isPackageValid: boolean) => void;
}

export interface VoucherError {
  type: string;
  message: string;
}

export interface ValidationResponse {
  valid: boolean;
  totalDuration: number;
  remainingMinutesBeforeBooking: number;
  remainingMinutesAfterBooking: number;
}

const useVoucherValidator = ({
  setIsPackageCodeValid,
}: VoucherValidatorInput) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<VoucherError>({
    type: "",
    message: "",
  });
  const [validationResponse, setValidationResponse] =
    useState<ValidationResponse>();
  const validateVoucher = async ({
    voucherCode,
    companyId,
    serviceId,
    serviceDurationId,
  }: ValidateVoucherInput) => {
    setIsLoading(true);
    setValidationError({
      type: "",
      message: "",
    });
    try {
      const { data: response } = await axiosInstance.post(
        `/booking/checkVoucherValidity`,
        {
          voucherCode,
          companyId,
          serviceId,
          serviceDurationId,
        }
      );
      if (response.error === 0 && response.data.valid) {
        setIsPackageCodeValid(true);
        setValidationResponse(response.data);
      } else {
        setIsPackageCodeValid(false);
        setValidationError({
          type: VoucherErrors.DURATION,
          message: "Package code does not cover the required duration",
        });
        setValidationResponse(response.data);
      }
    } catch (error) {
      setIsPackageCodeValid(false);
      setValidationError({
        type: VoucherErrors.INVALID_VOUCHER,
        message: "Package code does not exist",
      });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    validateVoucher,
    validationResponse,
    validationError,
    setValidationError,
  };
};

export default useVoucherValidator;
