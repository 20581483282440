import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import {
  AvailableStartingTimesResource
} from "../../../hooks/useAvailableStartingTimes";
import { isAfterCutOff, isWithinAdvanceBookingDays, withinWorkingHours } from "../../../Utils/format";
import SlotsSection from "./SlotsSection";
import { AvailableStartingTimesWrapper } from "./style";
import { areAllSlotsOfType, isInBookingRange } from "../../../Utils/validate";
import CalendarPriceCard from "./CalendarPriceCard";
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from "../../../Utils/constants";

interface Props {
  availableStartingTimes: AvailableStartingTimesResource;
  addToCart: (slot: any) => void;
  timeUnit: "Hour" | "Session";
  startTime?: string;
  endTime?: string;
}

export const AvailableStartingTimes: React.FC<Props> = ({
  availableStartingTimes,
  addToCart,
  timeUnit,
  startTime,
  endTime,
}) => {

  const theme = useTheme();
  
  const {
    company,
    quantity,
    selectedService,
    servicesMap,
    selectedDuration,
    slotsMap
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const service = servicesMap?.get(selectedService);

  const filterSlots = (isAfternoon: boolean) => {
    const bookingSlots = slotsMap.get(selectedDuration?.id!);
    if (!bookingSlots || !selectedDuration) return [];

    return bookingSlots.filter(slot => {
      const withinRange = isInBookingRange(company, slot.fullDate);
      const meetsQuantity = slot.available >= quantity;
      const timeCheck = isAfternoon ? slot.time >= "1200" : slot.time < "1200";
      const afterCutOff = isAfterCutOff(service?.cutoff, slot.fullDate, company?.timezone);
      const isActive = service?.isActive !== false; // Assuming isActive defaults to true if not specified
      const withinHours = withinWorkingHours(slot.time, startTime, endTime);
      const withinAdvanceBookingDays = isWithinAdvanceBookingDays(slot.fullDate, service?.advanceBookingDays, company?.timezone);

      return withinRange && meetsQuantity && timeCheck && afterCutOff && isActive && withinHours && withinAdvanceBookingDays;
    });
  };

  const morningStartingTimes = useMemo(() => filterSlots(false), [slotsMap, selectedDuration, company, quantity, service, startTime, endTime]);
  const afternoonStartingTimes = useMemo(() => filterSlots(true), [slotsMap, selectedDuration, company, quantity, service, startTime, endTime]);

  const hasAvailableSlots = morningStartingTimes.length > 0 || afternoonStartingTimes.length > 0;

  const areAllOffPeakSlots = areAllSlotsOfType([...morningStartingTimes, ...afternoonStartingTimes], false);

  if (!hasAvailableSlots) {
    return (
      <AvailableStartingTimesWrapper theme={theme} className="available-times">
        <Typography className="no-slots-label">
          Ughh, Seems like we are fully booked today. Join on us on another day?
        </Typography>
      </AvailableStartingTimesWrapper>
    );
  }

  return (
    <AvailableStartingTimesWrapper theme={theme} className="available-times">
       {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && (
          <Typography
            variant="body2"
            sx={{
              marginBottom: 2,
              fontStyle: 'italic',
              color: 'darkred'
            }}
          >
            3 court bookings are allowed per person per week          
          </Typography>
        )}

        {company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID && (
          <Typography
            variant="body2"
            sx={{
              marginBottom: 2,
              fontStyle: 'italic',
              color: 'darkred',
              marginTop: 2
            }}
          >
            All equipment is provided.
          </Typography>
        )}

        
      <Typography className="availability-Label">Available Starting Times</Typography>
      
      <CalendarPriceCard
        timeUnit={timeUnit}             
        morningStartingTimes={morningStartingTimes}
        afternoonStartingTimes={afternoonStartingTimes}
      />

      <Box sx={{ marginTop: "35px", display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
        {morningStartingTimes.length > 0 && (
          <SlotsSection
            label="Morning"
            slots={morningStartingTimes}
            availableStartingTimes={availableStartingTimes}
            addToCart={addToCart}
            areAllOffPeakSlots={areAllOffPeakSlots}
          />
        )}
        {afternoonStartingTimes.length > 0 && (
          <SlotsSection
            label="Afternoon"
            slots={afternoonStartingTimes}
            availableStartingTimes={availableStartingTimes}
            addToCart={addToCart}
            areAllOffPeakSlots={areAllOffPeakSlots}
          />
        )}
      </Box>
    </AvailableStartingTimesWrapper>
  );
};

export default AvailableStartingTimes;