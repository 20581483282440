import { Box, Theme } from "@mui/material";
import styled from "@emotion/styled";

interface IProps {
    theme: Theme;
}

export const ProfileTabsSwitcherWrapper = styled(Box)<IProps>(({ theme }) => ({
    margin: "0 auto",
  "& .MuiTabs-root": {
    minHeight: "70px",
    paddingY: "8px",
    paddingX: "11px",
    borderRadius: "16px",
    boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
    marginBottom: "30px",
  },
  "& .MuiTabs-flexContainer": {
    gap: "10px",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  "& .MuiTab-root": {
    fontSize: "14px",
    padding: "14px",
    borderRadius: "12px",
    height: "54px",
    textTransform: "none",
    fontWeight: 500,
    maxWidth: "50px",
    color: "#808080 !important",
    "&.Mui-selected": {
      background: theme.palette.primary.main,
      color: "#fff !important",
      boxShadow: "0 10px 40px #D4D9E8",
    },
  },
}));

