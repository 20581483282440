import React from "react";
import {
  Box,
  Stack,
  CircularProgress,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import TextDivider from "../../shared/TextDivider";
import { ReactComponent as AppleIcon } from "../../../assets/icons/apple.svg";
import appleIcon from "../../../assets/icons/apple-only.svg";
import googleIcon from "../../../assets/icons/google.svg";

import SocialCardLogin from "../../shared/SocialCardLogin";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { SocialMediaLoginProps } from "./Types";
import { LoadingButton } from "@mui/lab";

const SocialMediaLogin: React.FC<SocialMediaLoginProps> = ({
  textDivider,
  heading,
  onSubmit,
  isSocialLoading,
}) => {
  const isMd = useMediaQuery("(min-width:600px)");
  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");

  return (
    <>
      <Box
        component={"section"}
        width="100%"
        paddingX="47px"
        marginTop="-8px"
        sx={{ maxWidth: "570px", marginX: "auto" }}
      >
        <Stack direction={"row"} mt={3} justifyContent="center" gap="4px">
          {/* <SocialCardLogin
            icon={googleIcon}
            logoName="Google"
            onSubmit={() => onSubmit(googleProvider)}
            isLoading={isSocialLoading}
          /> */}
          <IconButton
            onClick={() => onSubmit(googleProvider)}
            sx={{
              position: "relative",
              width: "59px",
              hight: "56px",
              borderRadius: "50%",
              margin: (theme) => theme.spacing(0.5), // 1/10th of minHeight
            }}
          >
            <img src={googleIcon} style={{ width: "100%", height: "100%" }} />
          </IconButton>
          <IconButton
            onClick={() => onSubmit(appleProvider)}
            sx={{
              position: "relative",
              borderRadius: "50%",
              margin: (theme) => theme.spacing(0.5), // 1/10th of minHeight
            }}
          >
            <img
              src={appleIcon}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
            <span
              style={{
                display: "block",
                width: "72px",
                height: "72px",
                position: "absolute",
                border: "15px solid white",
                borderRadius: "50%",
              }}
            ></span>
          </IconButton>
          {/* <LoadingButton
            variant="contained"
            size="laWrge"
            startIcon={
              <AppleIcon
                width={isMd ? "24px" : "12px"}
                height={isMd ? "24px" : "12px"}
              />
            }
            sx={{
              "& .MuiButton-startIcon": {
                margin: "0 !important",
              },
            }}
            style={{
              textTransform: "none",
              fontWeight: "bold",
              textAlign: "center",
              alignItems: "center",
              width: "50%",
              height: isMd ? "48px" : "32px",
              backgroundColor: "white",
              boxShadow: "none",
              border: "1px solid #D3D9E0",
              color: "#000",
            }}
            onClick={() => onSubmit(appleProvider)}
            loading={isSocialLoading}
            loadingIndicator={
              <CircularProgress style={{ color: "white" }} size={16} />
            }
          ></LoadingButton> */}
        </Stack>
      </Box>
      <TextDivider text={textDivider} />
    </>
  );
};

export default SocialMediaLogin;
