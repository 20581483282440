import React, { useContext, useMemo } from "react";
import { VoucherData } from "../../../hooks/useProfile";
import { formatPrice } from "../../../Utils/format";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import dayjs from "dayjs";
import LandingPageTitle from "../../shared/LandingPageTitle";
import { Typography } from "@mui/material";
import { Company } from "../../../hooks/useCompany";
import UserVoucherCard from "./UserVoucherCard";

interface UserMembershipsSectionProps {
    vouchers: VoucherData[];
}

export const UserMembershipsSection: React.FC<UserMembershipsSectionProps> = ({ vouchers }) => {
    const { company } = useContext(CheckoutContext) as CheckoutContextProps;

    const membershipsDetails = useMemo(() => {
        return vouchers
            .filter((voucher) => voucher.type === "credit")
            .map((voucher) => createMembershipDetails(voucher, company));
    }, [vouchers, company?.currency]);

    return (
        <>
            <LandingPageTitle title="Memberships" />
            {membershipsDetails.length ? (
                membershipsDetails.map((membershipDetail) => (
                    <UserVoucherCard title={membershipDetail.name || ""} details={membershipDetail.details} />
                ))
            ) : (
                <Typography>No Memberships Available</Typography>
            )}
        </>
    );
};

const createMembershipDetails = (voucher: VoucherData, company?: Company) => {
    return {
        name: voucher.name,
        details: [
            {
                label: "Membership Code",
                value: voucher.code,
            },
            {
                label: "Membership Price",
                value: formatPrice(voucher.price, company),
            },
            {
                label: "Start Date",
                value: dayjs(voucher.startDate).format("MMM DD, YYYY") || "",
            },
            {
                label: "Valid Until",
                value: dayjs(voucher.endDate).format("MMM DD, YYYY") || "",
            },
        ],
    };
};

export default UserMembershipsSection;
