import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import PaginatedBookings from '../PaginatedBookings';
import { Booking } from '../../../hooks/useCart';
import { CheckoutContext, CheckoutContextProps } from '../../../context/CheckoutContext';
import { useNavigate } from 'react-router-dom';

interface UpcomingBookingsTabProps {
  upcomingBookings: Booking []; 
}

const UpcomingBookingsTab: React.FC<UpcomingBookingsTabProps> = ({ upcomingBookings }) => {
    const navigate = useNavigate();
    const { company } = useContext(CheckoutContext) as CheckoutContextProps;

    const handleCancelBooking = async (booking: Booking) => {
        navigate(`/cancellation?companyId=${company?.id}&cartId=${booking.cartId}&bookingId=${booking.id}`);
    }

  return (
    <>
      {upcomingBookings.length ? (
        <PaginatedBookings
          title="Upcoming Bookings"
          bookings={upcomingBookings}
          isCancelAddon={false}
          showCancelOption={true}
          onCancelBookingHandler={handleCancelBooking}
        />
      ) : (
        <Typography variant="body1" color="text.secondary">
          No upcoming bookings.
        </Typography>
      )}
    </>
  );
};

export default UpcomingBookingsTab;
