import axiosInstance from "../services/axiosInstance";
import { AddonPurchase, Booking } from "./useCart";

export interface CartData {
  addons: AddonPurchase[];
  bookings: Booking[];
  chargeId: string;
  company: object;
  companyId: string;
  createdAt: string;
  currency: string;
  customer: string;
  id: string;
  orderRefId: string;
  paymentStatus: string;
  status: string;
  userId: string;
}

export interface VoucherData {
  chargeId: string;
  code: string;
  companyId: string;
  companyName: string;
  createdAt: string;
  customer: {
    phone: string;
    name: string;
    id: string;
    email: string;
  };
  id: string;
  isActive: boolean;
  orderRefId: string;
  paymentStatus: string;
  paymentUrl: string;
  price: number;
  status: string;
  totalCredit: number;
  type: string;
  updatedAt: Date;
  usedCredit: number;
  userId: string;
  startDate?: string;
  endDate?: string;
  name?: string;
}

const useProfile = () => {
  const getUserBookings = async () => {
    try {
      const { data: response } = await axiosInstance.get(`/users/getBookings`);

      return response.carts as CartData[];
    } catch (error) {
      throw error;
    }
  };

  const getUserToken = async () => {
    try {
      const { data: response } = await axiosInstance.get(`/users/generateToken`);

      return response.data.token;
    } catch (error) {
      throw error;
    }
  };

  const getUserVouchers = async () => {
    try {
      const { data: response } = await axiosInstance.get(`/users/getVouchers`);

      return response.vouchers as VoucherData[];
    } catch (error) {
      throw error;
    }
  };

  const cancelBooking = async (bookingId: string) => {
    try {
      await axiosInstance.post(`/cart/cancelBooking`, { bookingId });
    } catch (error) {
      console.log("ERROR", error)
      throw error;
    }
  };

  return {
    getUserBookings,
    getUserVouchers,
    cancelBooking,
    getUserToken
  };
};

export default useProfile;
