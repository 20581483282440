import React, { useContext } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import useVoucherValidator from "../../hooks/useVoucherValidator";
import { VoucherErrors } from "../../Utils/constants";
import PackageCodeInput from "./PackageCodeInput";
import PackageDetails from "./PackageDetails";
import { PackageRedemptionWrapper } from "./style";

interface IProps {
  packageCode: string;
  setPackageCode: (packageCode: string) => void;
  isPackageCodeValid: boolean;
  setIsPackageCodeValid: (isTheCodeApplied: boolean) => void;
}

const PackageRedemption: React.FC<IProps> = ({
  packageCode,
  setPackageCode,
  isPackageCodeValid,
  setIsPackageCodeValid,
}) => {
  const { company, selectedDuration } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const {
    isLoading,
    validateVoucher,
    validationResponse,
    validationError,
    setValidationError,
  } = useVoucherValidator({
    setIsPackageCodeValid,
  });

  const onClickApply = () => {
    validateVoucher({
      voucherCode: packageCode,
      companyId: company?.id,
      serviceId: selectedDuration?.serviceId,
      serviceDurationId: selectedDuration?.id,
    });
  };

  const onClickCancel = () => {
    setPackageCode("");
    setValidationError({
      type: "",
      message: "",
    });
    setIsPackageCodeValid(false);
  };
  return (
    <PackageRedemptionWrapper>
      <PackageCodeInput
        packageCode={packageCode}
        setPackageCode={setPackageCode}
        isPackageCodeValid={isPackageCodeValid}
        isLoading={isLoading}
        validationError={validationError}
        onClickApply={onClickApply}
        onClickCancel={onClickCancel}
      />
      {isPackageCodeValid && (
        <PackageDetails
          validationResponse={validationResponse}
          isValidVoucher={true}
        />
      )}
      {validationError.type === VoucherErrors.DURATION && (
        <PackageDetails
          validationResponse={validationResponse}
          isValidVoucher={false}
        />
      )}
    </PackageRedemptionWrapper>
  );
};

export default PackageRedemption;
