import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const PackageConfirmationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin-top: 40px;

  .package-description {
    color: #808080;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 20px;
  }

  .customer-email {
    color: #080133;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-top: 5px;
  }

  .save-reminder {
    color: #808080;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    margin: 40px 0;
  }
`;
