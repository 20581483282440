import { createTheme } from "@mui/material/styles";

export const createCustomTheme = (primaryColor:string) => {
  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
    },
    typography: { fontFamily: ["Roboto", "sans-serif"].join(", ") },
  });
};