import { styled, Theme } from '@mui/material/styles';

export const WalletSectionWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  padding: '20px',
  textAlign: 'center',

  '& h2': {
    fontSize: '24px',
    marginBottom: '20px',
    color: theme.palette.text.primary,
  },
}));

export const ButtonsContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px', // Ensures clear space between buttons
  marginTop: '20px',

  '@media (max-width:600px)': {
    flexDirection: 'column',
    gap: '10px',
  },
}));

export const WalletButton = styled('button')(({ theme }: { theme: Theme }) => ({
  background: 'none',
  border: 'none',
  padding: '0',
  cursor: 'pointer',

  '& img': {
    height: '48px', // Minimum height as per Google Wallet guidelines
    width: 'auto',
    transition: 'transform 0.2s',

    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
}));
