import React, { useContext, useState, useMemo } from "react";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import { AppBar, Button, Badge, IconButton, Stack, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { ReactComponent as ShoppingCartIcon } from "../../../assets/icons/shopping-cart.svg";
import Sidebar from "../Sidebar/Sidebar";
import ControlledCountdown from "../controlledCountdown";
import { ROUTES_WITHOUT_CART_ICON } from "../../../Utils/constants";
import { DUBAI_FITNESS_CHALLENGE_COMPANY_ID } from "../../../Utils/constants";
import { Company } from "../../../hooks/useCompany";

const AppTopNav: React.FC<{ company: Company | undefined }> = ({ company }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { bookingCart , marketplaceName } = useContext(CheckoutContext) as CheckoutContextProps;

  const showCart = useMemo(() => 
    !ROUTES_WITHOUT_CART_ICON.includes(location.pathname.toLowerCase()),
    [location.pathname]
  );

  const handleNavigateToCart = () => {
    if(marketplaceName){
      navigate(`/Cart?companyId=${company?.id}&marketplaceName=${marketplaceName}`);
    }else{
      navigate(`/Cart?companyId=${company?.id}`);
    }
  };

  const handleToggleDrawer = (open: boolean) => setIsOpen(open);

  const handleLogoClick = () => {
    if (company?.id === DUBAI_FITNESS_CHALLENGE_COMPANY_ID) {
      navigate(`/?companyId=${company?.id}`);
    } else {
      window.open(company?.website || "", "_blank");
    }
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          boxShadow: "none",
          width: location.pathname === "/" ? "calc(100% - 40px)" : "100%",
          padding: "0 15px",
          background: "#fff",
        }}
        className="app-header"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "5px 0", background: "transparent", width: "100%" }}
        >
          <MenuButton showSidebar={company?.showSidebar} onOpen={() => handleToggleDrawer(true)} />
          <LogoSection company={company} onClick={handleLogoClick} />
          <CartButton showCart={showCart} cartItemsCount={bookingCart?.length} onClick={handleNavigateToCart} />
        </Stack>
      </AppBar>
      {showCart && (
        <Box>
          <ControlledCountdown />
        </Box>
      )}
      {company?.showSidebar && (
        <Sidebar isOpen={isOpen} toggleDrawer={handleToggleDrawer} />
      )}
    </>
  );
};

const MenuButton: React.FC<{ showSidebar?: boolean; onOpen: () => void }> = ({ showSidebar, onOpen }) => (
  <IconButton
    size="large"
    edge="start"
    color="default"
    aria-label="menu"
    onClick={onOpen}
  >
    {showSidebar && <MenuIcon />}
  </IconButton>
);

const LogoSection: React.FC<{ company: any; onClick: () => void }> = ({ company, onClick }) => (
  <div
    style={{
      textAlign: "center",
      width: "300px",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <Box>
      <img
        src={company?.logo}
        alt={`${company?.name} Logo`}
        width={company?.logoDimensions?.width || 120}
        height={company?.logoDimensions?.height || 90}
      />
    </Box>
  </div>
);

const CartButton: React.FC<{ showCart: boolean; cartItemsCount?: number; onClick: () => void }> = ({ showCart, cartItemsCount, onClick }) => (
  <Button
    variant="text"
    sx={{
      p: 0,
      minWidth: 0,
      "&:hover": {
        bgcolor: "transparent",
      },
    }}
    onClick={onClick}
  >
    {showCart && (
      <Badge badgeContent={cartItemsCount} color="primary">
        <ShoppingCartIcon />
      </Badge>
    )}
  </Button>
);

export default AppTopNav;
