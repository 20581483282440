import { Stack, Typography } from "@mui/material";
import React from "react";
import { ITextDividerProps } from "./Types";

const TextDivider: React.FC<ITextDividerProps> = ({ text }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent="center"
      position={"relative"}
      width={"100%"}
      sx={{
        "&:before": {
          content: "''",
          position: "absolute",
          height: "1px",
          width: "100%",
          bgcolor: "#858686",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: -1,
          minWidth: "250px",
        },
      }}
    >
      <Typography
        maxWidth={"40%"}
        textAlign="center"
        bgcolor={"white"}
        color="#858686"
        px={2}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default TextDivider;
