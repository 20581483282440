import { Typography } from "@mui/material";
import React, { useContext } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { ControlledCountdownWrapper } from "./style";

const ControlledCountdown: React.FC = () => {
  const { countdownApi } = useContext(CheckoutContext) as CheckoutContextProps;

  const { minutes, seconds, isRunning } = countdownApi;

  return (
    <ControlledCountdownWrapper>
      {isRunning && (
        <Typography
          sx={{
            color: "#E50909",
            fontSize: "14px",
            textAlign: "center",
            fontFamily: "inter, sans-serif",
          }}
        >
          Items in cart for
          <Typography
            display={"block"}
            fontFamily={"inter, sans-serif"}
            component="span"
          >{`${minutes.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${seconds.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`}</Typography>
        </Typography>
      )}
    </ControlledCountdownWrapper>
  );
};

export default ControlledCountdown;
