import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { down } from "styled-breakpoints";

interface IProps {
  theme: any;
}
export const SlotSelectorWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .no-slots-label {
    text-align: center;
  }

  .gym-label {
    text-align: center;
  }

  .duration-wrapper {
    padding: 0;
    margin: 0;
    justify-content: space-between;
    gap: 20px;

    .duration-chip {
      margin: 0;
      border-radius: 12px;
      box-shadow: none;
      height: 54px;
      width: auto;

      &--active {
        background: ${(props) => props.theme.palette.primary.main};
        color: #fff;
        box-shadow: 0px 10px 40px #d4d9e8;
      }

      .duration {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .available-times {
    box-shadow: none;
    margin-top: 34px;

    > p {
      margin-top: 0;
    }

    .price-section p {
      color: #9b2263;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .slots-section {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    box-shadow: none;
    background: transparent;
  }
`;

export const DurationFilterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  .duration-label {
    color: #33334f;
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    margin-bottom: 12px;
  }
`;

export const DurationListWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // width: 100%;
  // box-shadow: 0px 6px 40px 0px rgba(208, 208, 208, 0.4);
  border-radius: 16px;
  background-color: #fff;
  padding: 10px 20px;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  justify-content: space-between;
`;

interface DurationChipWrapperProps {
  isSelected: boolean;
}
export const DurationChipWrapper = styled(Box)<DurationChipWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  padding: 10px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #ffffff;

  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  line-height: 25px;
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#808080")};
  background-color: ${({ isSelected }) => (isSelected ? "#5182FF" : "#fff")};
  box-shadow: 0px 6px 40px 0px rgba(208, 208, 208, 0.4);
  border-radius: 12px;

  .duration {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-weight: normal;
    line-height: 25px;
  }

  .price {
    margin: 0;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: Roboto-thin;
    font-weight: normal;
    line-height: 25px;
  }
`;

export const AvailableStartingTimesWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  border-radius: 16px;
  margin-top: 20px;

  .availability-Label {
    color: #33334f;
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .price-section {
    width: 80%;
    background: #ffffff;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
    .label {
      font-size: 24px;
      font-style: normal;
      text-align: center;
      font-weight: 500;
      color: ${(props) => props.theme.palette.primary.main} !important;
    }
  }

  .no-slots-label {
    color: #000000;
    padding: 30px 20px;
    font-size: 18px;
    text-align: center;
    font-weight: normal;
    line-height: 140%;
  }
`;

export const SlotsSectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: calc(100% - 20px);
  background: #ffffff;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 20px;
  margin-top: 5px;

  .label {
    color: #33334f;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-weight: normal;
  }
`;

export const SlotsListWrapper = styled(Box)<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 20px;

  // box-shadow: 0px 6px 40px 0px rgba(208, 208, 208, 0.2);
  margin-top: 8px;
  width: 100%;
  padding: 0;
  row-gap: 24px;
  column-gap: 16px;

  .slot-chip {
    width: 100%;
    padding: 11px 18px;
    margin: 0;
    box-shadow: none;
    border-radius: 12px;

    p {
      font-size: 14px;
      font-weight: 300;
    }

    &--active,
    &:hover {
      background: ${(props) => props.theme.palette.primary.main};
      color: #fff;
    }

    &--peak {
      background: #fde9f1;
      color: #808080;
    }
  }

  .MuiBadge-badge {
    color: #9b2263;
    font-size: 10px;
    font-weight: 500;
    transform: scale(1) translate(0%, -50%);
  }
`;

interface SlotChipWrapperProps {
  isSelected: boolean;
}
export const SlotChipWrapper = styled(Box)<SlotChipWrapperProps>`
  &:hover {
    cursor: pointer;
  }
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  border-radius: 5px;
  padding: 8px;
  margin: 5px;
  margin-top: 10px;
  color: #000000;
  font-size: 12px;
  text-align: center;
  font-family: Roboto-thin;
  font-weight: normal;
  line-height: 140%;
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#808080")};
  background-color: ${({ isSelected }) => (isSelected ? "#5182FF" : "#fff")};
  &:hover {
    cursor: pointer;
    background-color: #5182ff;
    color: #fff;
  }
`;

export const PopoverWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-shadow: 0px 6px 40px rgb(208 208 208 / 20%);
  // padding: 20px;

  .cart-button {
    width: 140px;
    height: 50px;
    &:hover {
      cursor: pointer;
      background-color: #5182ff;
      color: #fff;
    }
  }

  .popover-item {
    margin: 10px;
    color: #000000;
    font-size: 14px;
    text-align: center;
    font-family: Roboto-thin;

    line-height: 140%;
    &:hover {
      cursor: pointer;
      background-color: #5182ff;
      color: #fff;
    }
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 6px 40px rgb(208 208 208 / 50%);
  }
`;

export const SlotConfirmationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .cart-button {
    padding: 5px;
    background: red;
  }
`;
