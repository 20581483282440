import { Badge, Popover, Typography } from "@mui/material";

import React from "react";
import { AvailableStartingTimesSlot } from "../../../hooks/useAvailableStartingTimes";
import { PopoverWrapper, SlotChipWrapper } from "./style";

import { getFormattedTime, getTimezone } from "../../../Utils/format";

import SlotConfirmation from "./SlotConfirmation";

interface IProps {
  slot: AvailableStartingTimesSlot;
  addToCart: any;
  areAllOffPeakSlots: boolean;
  selectedSlot?: AvailableStartingTimesSlot;
  setSelectedSlot: (slot: AvailableStartingTimesSlot | undefined) => void;
  timezone?: string;
}
const SlotChip: React.FC<IProps> = ({
  slot,
  addToCart,
  areAllOffPeakSlots,
  selectedSlot,
  setSelectedSlot,
  timezone,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent) => {
    setSelectedSlot(slot);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedSlot(undefined);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isSelected = selectedSlot?.time === slot.time;

  return (
    <>
      <Badge
        badgeContent={slot.isPeak || areAllOffPeakSlots ? "" : "off-peak"}
        sx={{
          boxShadow: "0px 6px 40px 0px rgba(208, 208, 208, 0.2)",
          borderRadius: "12px",
        }}
      >
        <SlotChipWrapper
          onClick={handleClick}
          aria-describedby={id}
          isSelected={isSelected}
          className={`slot-chip ${isSelected ? "slot-chip--active" : ""}`}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px !important",
              fontWeight: "400 !important",
              lineHeight: "19.6px",
              whiteSpace: "nowrap",
            }}
          >
            {getFormattedTime(
              slot.fullDate,
              "hh:mm A",
              false,
              getTimezone(timezone)
            )}
          </Typography>
        </SlotChipWrapper>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
            borderRadius: "8px",
            paddingY: "12px",
            paddingX: "16px",
          },
        }}
      >
        <PopoverWrapper>
          <SlotConfirmation
            addToCart={addToCart}
            setAnchorEl={setAnchorEl}
            selectedSlot={selectedSlot}
          />
        </PopoverWrapper>
      </Popover>
    </>
  );
};

export default SlotChip;
