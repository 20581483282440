import dayjs from "dayjs";
import { useState } from "react";

import {
  getFormattedTime,
  getDateInTimezone,
  formatConfirmationSearchParams,
} from "../Utils/format";
import {
  AvailableStartingTimesDuration,
  AvailableStartingTimesSlot,
} from "./useAvailableStartingTimes";
import axiosInstance from "../services/axiosInstance";

interface IProps {
  selectedResource: string;
  selectedServiceId: string;
  selectedDuration: AvailableStartingTimesDuration;
  selectedSlot: AvailableStartingTimesSlot;
  customerInformation: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  packageCode: string;
  quantity: number;
}

const getStartTime = (slot: AvailableStartingTimesSlot) => {
  return getFormattedTime(slot.fullDate, "HHmm", false, "Asia/Dubai");
};

const getEndTime = (
  slot: AvailableStartingTimesSlot,
  selectedDuration: AvailableStartingTimesDuration
) => {
  return getDateInTimezone(slot.fullDate, false, "Asia/Dubai")
    .add(selectedDuration.durationTime, "minutes")
    .format("HHmm");
};
const useConfirmBooking = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bookingError, setBookingError] = useState<string | null>(null);
  const [bookingResponse, setBookingResponse] = useState<any | null>(null);

  const confirmBooking = async ({
    selectedResource,
    selectedServiceId,
    selectedSlot,
    customerInformation,
    selectedDuration,
    packageCode,
    quantity,
  }: IProps) => {
    setIsLoading(true);
    setBookingError(null);
    try {
      const { data: response } = await axiosInstance.post(`/addBooking`, {
        resId: selectedResource,
        serviceId: selectedServiceId,
        serviceDurationId: selectedDuration.id,
        date: dayjs(selectedSlot.fullDate).format("DDMMYYYY"),
        startTime: getStartTime(selectedSlot),
        endTime: getEndTime(selectedSlot, selectedDuration),
        firstName: customerInformation.firstName,
        lastName: customerInformation.lastName,
        email: customerInformation.email,
        phone: customerInformation.phone,
        voucherCode: packageCode,
        quantity,
      });
      if (response.error === 0) {
        if (packageCode) {
          console.log("Response", response);
          window.location.replace(
            `${window.location.origin}/${formatConfirmationSearchParams(
              response.data
            )}`
          );
        } else {
          window.location.replace(response.data.paymentUrl);
        }
        setBookingResponse(response.data);
      } else {
        setBookingError(response.message);
      }
    } catch (e: any) {
      console.log("ERROR", e);
      setBookingError(e?.response?.data?.message);
    }
    setIsLoading(false);
  };
  return {
    confirmBooking,
    isLoading,
    bookingError,
    bookingResponse,
  };
};

export default useConfirmBooking;
