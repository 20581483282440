import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const PromocodeInputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  .package-code-label {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #080133;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .package-code-input {
    border: 1px solid #d1d5db;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .validation-error {
    color: #ff0000;
    font-style: normal;
    font-size: 13px;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;
  }
`;
