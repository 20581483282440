import { useState } from "react";
import axiosInstance from "../services/axiosInstance";

const useWallets = () => {
  const [isLoadingGooglePassBooking, setIsLoadingGooglePassBooking] = useState<boolean>(false);
  const [googlePassBookingError, setGooglePassBookingError] = useState<string | null>(null);

  const [isLoadingGooglePassUser, setIsLoadingGooglePassUser] = useState<boolean>(false);
  const [googlePassUserError, setGooglePassUserError] = useState<string | null>(null);

  const [isLoadingApplePassBooking, setIsLoadingApplePassBooking] = useState<boolean>(false);
  const [applePassBookingError, setApplePassBookingError] = useState<string | null>(null);

  const [isLoadingApplePassUser, setIsLoadingApplePassUser] = useState<boolean>(false);
  const [applePassUserError, setApplePassUserError] = useState<string | null>(null);

  const generateGooglePassForBooking = async (bookingId: string) => {
    setIsLoadingGooglePassBooking(true);
    setGooglePassBookingError(null);
    try {
      const response = await axiosInstance.get(`/users/generateGooglePassForBooking/${bookingId}`);
      setIsLoadingGooglePassBooking(false);
      return response.data.data;
    } catch (error: any) {
      setGooglePassBookingError(error?.response?.data?.message || "An error occurred while generating Google Pass for booking.");
      setIsLoadingGooglePassBooking(false);
      throw error;
    }
  };

  const generateGooglePassForUser = async () => {
    setIsLoadingGooglePassUser(true);
    setGooglePassUserError(null);
    try {
      const response = await axiosInstance.get(`/users/generateGooglePassForUser`);
      setIsLoadingGooglePassUser(false);
      return response.data.data;
    } catch (error: any) {
      setGooglePassUserError(error?.response?.data?.message || "An error occurred while generating Google Pass for user.");
      setIsLoadingGooglePassUser(false);
      throw error;
    }
  };

  const generateApplePassForBooking = async (bookingId: string) => {
    setIsLoadingApplePassBooking(true);
    setApplePassBookingError(null);
    try {
      const response = await axiosInstance.get(`/users/generateApplePassForBooking/${bookingId}`);
      setIsLoadingApplePassBooking(false);
      return response.data.data;
    } catch (error: any) {
      setApplePassBookingError(error?.response?.data?.message || "An error occurred while generating Apple Pass for booking.");
      setIsLoadingApplePassBooking(false);
      throw error;
    }
  };

  const generateApplePassForUser = async () => {
    setIsLoadingApplePassUser(true);
    setApplePassUserError(null);
    try {
      const response = await axiosInstance.get(`/users/generateApplePassForUser`);
      setIsLoadingApplePassUser(false);
      return response.data.data;
    } catch (error: any) {
      setApplePassUserError(error?.response?.data?.message || "An error occurred while generating Apple Pass for user.");
      setIsLoadingApplePassUser(false);
      throw error;
    }
  };

  return {
    generateGooglePassForBooking,
    isLoadingGooglePassBooking,
    googlePassBookingError,

    generateGooglePassForUser,
    isLoadingGooglePassUser,
    googlePassUserError,

    generateApplePassForBooking,
    isLoadingApplePassBooking,
    applePassBookingError,

    generateApplePassForUser,
    isLoadingApplePassUser,
    applePassUserError,
  };
};

export default useWallets;

