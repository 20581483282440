import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { IconButton } from "@mui/material";

interface PaginationProps {
  current: number;
  total: number;
  onNext: () => void;
  onPrev: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  current,
  total,
  onNext,
  onPrev,
}) => {
  const getIndecies = () => {
    // If currentPage = 1 then return [1, 5]
    // If currentPage = 2 then return [6, 10] and so on
    return `${(current - 1) * 5 + 1}-${Math.min(current * 5, total)}`;
  };

  return (
    <p
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: "auto",
        color: "#808080",
      }}
    >
      {getIndecies()} of {total}
      <IconButton onClick={onPrev} disabled={current === 1}>
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton onClick={onNext} disabled={current === Math.ceil(total / 5)}>
        <NavigateNextIcon />
      </IconButton>
    </p>
  );
};

export default Pagination;
